class Slider {

    defaultOptions() {
        return {
            '$container': null,
            'paginationClass': '.swiper-pagination',
            'btnPrevClass': '.btn-previous',
            'btnNextClass': '.btn-next',
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onChange = this.onChange.bind(this);

        this.$container = this.settings.$container;
        if (!this.$container) {
            console.error("Can't instanciate Swiper object without $container");
        }

        this.container = this.$container[0];

        this.nbShown = this.$container.data('nb-shown');
        this.nbLoaded = this.$container.data('nb-loaded');
        this.loop = this.$container.data('loop');
        this.direction = this.$container.data('direction');
        this.url = this.$container.data('url');
        this.method = this.$container.data('method');
        this.autoplayDelay = this.$container.data('autoplay-delay');
        this.noSwiping = false;
        this.breakpoints = this.$container.data('breakpoints');
        this.spaceBetween = this.$container.data('space-between');

        this.async = !!(this.url && this.method);


        this.hasPagination = this.$container.data('has-pagination');
        this.hasControl = this.$container.data('has-control');
        this.$slideWrapper = $('.swiper-wrapper', this.$container);
        this.$controlWrapper = $('.swiper-control-wrapper', this.$container);
        this.$pagination = $('.swiper-pagination', this.$container);


        this.initialize();
    }

    initialize() {


        this.$loader = App.renderLoader();
        this.$loader.hide();
        this.$slideWrapper.before(this.$loader);


        this.start();
        this.alternateNavigation();
    }

    createSwiperInstance() {
        if (this.swiper) {
            this.swiper.destroy(true, true);
        }
        this.swiper = new Swiper(this.container, this.getSwiperOptions());
        this.swiper.on('slideChangeTransitionEnd', this.onChange);
    }

    start() {
        if (this.async) {
            this.load();
        } else {
            this.createSwiperInstance();
        }
    }

    getSwiperOptions() {
        let options = {
            'slidesPerView': this.nbShown,
            'noSwiping': this.noSwiping,

            // Optional parameters
            'direction': this.direction,
            'loop': this.loop,
        };

        if (this.hasPagination) {
            options.pagination = {
                'el': this.settings.paginationClass,
            };
        }

        if (this.hasControl) {
            options.navigation = {
                'prevEl': this.settings.btnPrevClass,
                'nextEl': this.settings.btnNextClass,
            };
        }

        if (this.autoplayDelay) {
            options.autoplay = {
                'delay': this.autoplayDelay
            };
        }

        if (this.spaceBetween) {
            options.spaceBetween = this.spaceBetween;
        }

        if (this.breakpoints) {

            if ($(this.container).hasClass('property-block-estimate')) {
                options.autoplay = {
                    delay: 3000,
                }
                options.breakpoints = {
                    200: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                    1440: {
                        slidesPerView: 3.3,
                        spaceBetween: 30,
                    },
                }
            } else if ($(this.container).hasClass('property-block')) {
                options.breakpoints = {
                    200: {
                        slidesPerView: 1.3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1440: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                }
            } else if ($(this.container).hasClass('review-block')) {
                options.autoplay = {
                    delay: 3000
                }
                options.breakpoints = {
                    200: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 2.2,
                        spaceBetween: 30,
                    },
                    1440: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                }
            } else if ($(this.container).hasClass('agent-block-estimate')) {
                options.autoplay = {
                    enabled: false
                }

                options.breakpoints = {
                    200: {
                        slidesPerView: 1.3,
                        spaceBetween: 0,
                        autoplay: {
                            enabled: true,
                            delay: 3000
                        },
                    },
                    768: {
                        slidesPerView: 2.3,
                        spaceBetween: 0,
                        autoplay: {
                            enabled: true,
                            delay: 3000
                        },
                    },
                    991: {
                        slidesPerView: 2.1,
                        spaceBetween: 0,
                        autoplay: {
                            enabled: true,
                            delay: 3000
                        },
                    },
                    1280: {
                        slidesPerView: 2.8,
                        spaceBetween: 0,
                    },
                    1440: {
                        slidesPerView: 4.1,
                        spaceBetween: 0,
                    },
                }
            } else if ($(this.container).hasClass('agent-block')) {
                options.autoplay = {
                    delay: 3000
                }
                options.breakpoints = {
                    200: {
                        slidesPerView: 1.7,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3.7,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.1,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 2.8,
                        spaceBetween: 30,
                    },
                    1440: {
                        slidesPerView: 2.8,
                        spaceBetween: 20,
                    },

                    1900: {
                        slidesPerView: 4.1,
                        spaceBetween: 20,
                    }
                }
            } else if ($(this.container).hasClass('project-block')) {
                options.autoplay = {
                    delay: 3000
                }
                options.breakpoints = {
                    100: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                }
            }
            else if ($(this.container).hasClass('project-block-lp')) {
                options.autoplay = {
                    enabled: false
                }
                options.breakpoints = {
                    100: {
                        slidesPerView: 0.96,
                        spaceBetween: 10,
                    },
                    330: {
                        slidesPerView: 1.1,
                        spaceBetween: 8,
                    },

                    390: {
                        slidesPerView: 1.15,
                        spaceBetween: 8,
                    },

                    700: {
                        slidesPerView: 1.3,
                        spaceBetween: 10,
                    },

                    769: {
                        slidesPerView: 1.5,
                        spaceBetween: 10,
                    },
                    991: {
                        slidesPerView: 2.8,
                        spaceBetween: 10,
                    },

                    1200: {
                        slidesPerView: 1.7,
                        spaceBetween: 20,
                    },

                    1260: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                }
            } else {
                options.breakpoints = {
                    100: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.8,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2.5,
                        spaceBetween: 30,
                    },
                }
            }
        }

        return options;
    }

    clear() {
        this.$slideWrapper.html('');
    }

    before() {
        this.clear();

        this.$loader.show();

        if (this.hasControl) {
            this.$controlWrapper.hide();
        }
        if (this.hasPagination) {
            this.$pagination.hide();
        }
    }

    after() {
        this.$loader.hide();

        if (this.hasControl) {
            this.$controlWrapper.show();
        }

        if (this.hasPagination) {
            this.$pagination.show();
        }
    }

    getFirstActiveItem() {
        let $slides = $('.swiper-slide-active', this.$slideWrapper);
        return $slides.first();
    }

    getRequestData() {
        return {};
    }

    load() {
        let self = this;

        this.before();

        let data = this.getRequestData();

        $.ajax({
            'url': this.url,
            'method': this.method,
            'data': data
        })
            .done(function (response, textStatus, jqXhr) {
                self.done(response, textStatus, jqXhr);
            })
            .fail(function (jqXhr, textStatus, errorThrown) {
                let response = jqXhr.responseText;
                self.fail(response, textStatus, jqXhr);
            })
            .always(function () {
                self.after();
            });

    }


    done(response, textStatus, jqXhr) {
        this.fillSwiper(response);
        this.createSwiperInstance();
    }

    fail(response, textStatus, jqXhr) {
        let $message = $('<div/>')
            .addClass('alert alert-danger')
            .append("Une erreur est advenue lors du chargement.");
        this.$slideWrapper.html('').append($message);
    }

    fillSwiper(response) {
        let items = response.data;
        let meta = response.meta;

        for (var i in items) {
            let item = items[i];
            let $item = this.drawItem(item);
            this.$slideWrapper.append($item);
        }
    }

    drawItem(item) {
        return $('<div/>')
            .addClass('swiper-slide swiper-lazy')
            .attr('data-item', JSON.stringify(item))
            .html(item.html);
    }

    onChange(event) {

    }

    alternateNavigation() {
        if ($(".alt-swiper-nav-buttons").length > 0) {
            $(".alt-swiper-button-prev").click(function () {
                $(this).parents('.alt-swiper-container').find('.btn-previous').click();
                if ($(this).parents('.alt-swiper-container').find('.btn-previous').hasClass('swiper-button-disabled')) {
                    $(this).addClass('swiper-button-disabled');
                }
                if ($(this).parents('.alt-swiper-container').find('.btn-next').hasClass('swiper-button-disabled')) {
                    $(this).parent('.alt-swiper-nav-buttons').find('.alt-swiper-button-next').addClass('swiper-button-disabled');
                } else {
                    $(this).parent('.alt-swiper-nav-buttons').find('.alt-swiper-button-next').removeClass('swiper-button-disabled');
                }
            });

            $(".alt-swiper-button-next").click(function () {
                $(this).parents('.alt-swiper-container').find('.btn-next').click();
                if ($(this).parents('.alt-swiper-container').find('.btn-next').hasClass('swiper-button-disabled')) {
                    $(this).addClass('swiper-button-disabled');
                }
                if ($(this).parents('.alt-swiper-container').find('.btn-previous').hasClass('swiper-button-disabled')) {
                    $(this).parent('.alt-swiper-nav-buttons').find('.alt-swiper-button-prev').addClass('swiper-button-disabled');
                } else {
                    $(this).parent('.alt-swiper-nav-buttons').find('.alt-swiper-button-prev').removeClass('swiper-button-disabled');
                }
            });
        }
    }
}

module.exports = Slider;
