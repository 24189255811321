class View {

    constructor(options) {
        this.initialize();
    }

    initialize() {
        this.faqClick();
    }


    faqClick() {
        $("body").on("click", ".faq-element", function () {
            if (!$(this).hasClass('opened')) {
                $('.faq-element').removeClass('opened');
                $(this).addClass('opened');
            } else {
                $(this).removeClass('opened');
            }
        });
    }

}

module.exports = View;
