class Layout {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;

        this.initialize();
    }

    initialize(){
    }

}

module.exports = Layout;
