const Form = require('app/b2c/agent/search/Form');
const List = require('app/b2c/agent/search/List');

class View {

    defaultOptions() {
        return {
            '$container': null,
            '$formContainer': null,
            '$formFullContainer': null,
            'extend': false,
            'length': 12,
            'lengthOptions': null,

            'result': null,//give api result to init pagination at startup
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
        this.drawResult = this.drawResult.bind(this);

        this.$container = this.settings.$container;
        this.$formContainer = this.settings.$formContainer;
        this.$resultContainer = $('.agent-search-result-container', this.$container);

        this.length = this.settings.length;

        this.initialize();
    }

    initialize() {
        if (this.$formContainer) {
            this.form = new Form({
                'before': this.onBeforeSubmit,
                '$container': this.$formContainer,
                'draw': this.drawResult,
                'length': this.length,
            });
        }

        if (this.settings.result) {
            this.drawResult(this.form, this.settings.result);
        }
    }

    drawResult(form, result) {

        let list = new List({
            'view': this,
            'result': result,
            'length': this.length,
            'lengthOptions': this.settings.lengthOptions,
        });

        this.$resultContainer
            .html('')
            .append(list.render());
    }

    onBeforeSubmit(form) {
        this.$resultContainer
            .html('')
            .append(window.App.renderLoader());
    }

    setPage(page) {
        return this.form.setPage(page);
    }

    setLength(length) {
        this.length = length;
        return this.form.setLength(this.length);
    }

    reload() {
        this.form.submit();
    }
}

module.exports = View;
