
class View {

    constructor(options) {
        this.initialize();
    }

    initialize(){
		this.tabsInteractions();
		this.packInteractions();
        this.onVideoCarrousel();
        this.onBlockBlueSwiper();
		this.partnerSwiper();

    }

	tabsInteractions(){
		$(".block-home-tabs").on("click",".tab-link",function(){
			let el = $(this);
			$(".tab-link.active").removeClass('active');
			el.addClass('active');
			$(".tab.active").removeClass('active');
			$(".tab." + el.data('tab')).addClass('active');
			return false;
		});
	}

    packInteractions(){
        $(".home-packs__item--open").click(function(){
            let el = $(this);
            el.closest('.home-packs__item').toggleClass('active');
        });

        $(".home-packs__item--close").click(function(){
            let el = $(this);
            el.closest('.home-packs__item').toggleClass('active');
        });
    }

    onBlockBlueSwiper(){
        if($(".block-blue-swiper--content .swiper-slide").length > 0){
            new Swiper('.block-blue-swiper--content', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.1,
                freeMode: true,
                watchSlidesProgress: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.block-blue-swiper-button-next',
                    prevEl: '.block-blue-swiper-button-prev',
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.7,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.2,
                        spaceBetween: 40,
                    },
                    1290: {
                        slidesPerView: 2.8,
                        spaceBetween: 40,
                    },
                }
            });
        }
    }

    onVideoCarrousel(){
        if($(".video_text__items .swiper-slide").length > 0){
            new Swiper('.video_text__items', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.2,
                freeMode: true,
                watchSlidesProgress: true,
                // If we need pagination
                pagination: {
                    el: '.videos-swiper-pagination',
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.videos-swiper-button-next',
                    prevEl: '.videos-swiper-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    500: {
                        slidesPerView: 1.7,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }

    partnerSwiper(){
        if($(".partner-block").length > 0){
            new Swiper('.partner-block', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 2,
				loop: true,
				autoplay: {
					delay: 1000,
				  },
                watchSlidesProgress: false,
                breakpoints: {
                    400: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }
}

module.exports = View;
