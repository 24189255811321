const UIForm = require('app/ui/Form');

const LocationPicker = require('app/ui/input/Location');

class Form extends UIForm {

    defaultOptions() {
        let options = {
            'formSelector': '.agent-search-form',
            '$container': null,
            'draw': function (form, result) {
            },
            'sort': null,
            'page': null,
            'length': 12,
        };
        return Object.assign(super.defaultOptions(), options);
    }

    initialize() {
        this.onAcceptGeolocalisation = this.onAcceptGeolocalisation.bind(this);
        this.onRefuseGeolocalisation = this.onRefuseGeolocalisation.bind(this);

        super.initialize();

        this.defaultSort = null;
        this.distanceSort = 'distance';
        this.sort = this.defaultSort;
        this.page = null;
        this.length = this.settings.length;

        this.locationPicker = new LocationPicker({
            '$container': $('.location-picker', this.$form),
            'onAcceptGeolocalisation': this.onAcceptGeolocalisation,
            'onRefuseGeolocalisation': this.onRefuseGeolocalisation,
            'type': 'department',
        })

        this.cityLocationPicker = new LocationPicker({
            '$container': $('.city-location-picker', this.$form),
            'onAcceptGeolocalisation': this.onAcceptGeolocalisation,
            'onRefuseGeolocalisation': this.onRefuseGeolocalisation,
            'type': 'city',
        })

        return this;
    }

    setPage(page) {
        this.page = page;
    }

    setLength(length) {
        this.length = length;
    }

    onChange(event) {
        this.setPage(null);
        super.onChange(event);
    }

    onDone(response, textStatus, jqXhr) {
        super.onDone(response, textStatus, jqXhr);

        this.settings.draw(this, response);
    }

    getData() {
        let data = super.getData();
        if (this.sort) {
            let sort = this.sort;
            if ((this.sort == this.distanceSort
                    && this.locationPicker.getType() != 'aroundme') ||
                (this.sort == this.distanceSort
                    && this.cityLocationPicker.getType() != 'aroundme')) {
                sort = this.defaultSort;
            }

            if (sort) {
                data.push({
                    'name': 'sort',
                    'value': sort,
                });
            }
        }

        if (this.page) {
            data.push({
                'name': 'page[number]',
                'value': this.page,
            });
        }

        if (this.length) {
            data.push({
                'name': 'page[size]',
                'value': this.length,
            });
        }

        return data;
    }

    onAcceptGeolocalisation() {
        if (this.sort != this.distanceSort) {
            this.sort = this.distanceSort;
        }
    }

    onRefuseGeolocalisation() {
        if (this.sort != this.defaultSort) {
            this.sort = this.defaultSort;
        }
    }

}

module.exports = Form;
