
const Form = require('app/b2c/ad/search/Form');
const List = require('app/b2c/ad/search/List');

class View {

	defaultOptions() {
		return {
			'$container': null,
			'$formContainer': null,
			'$formFullContainer': null,
			'extend': false,

			'result': null,//give api result to init pagination at startup
		};
	}

	constructor(options) {
		this.settings = $.extend(this.defaultOptions(), options);

		this.drawResult = this.drawResult.bind(this);
		this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
		this.onClickExtend = this.onClickExtend.bind(this);

		this.$container = this.settings.$container;
		this.$formContainer = this.settings.$formContainer;
		this.$formFullContainer = this.settings.$formFullContainer;
		this.$resultContainer = $('.ad-search-result-container',this.$container);

		this.$btnCollapseForm = $('.btn-collapse',this.$formContainer);
		this.$btnCloseFullForm = $('.btn-close',this.$formFullContainer);

		this.initialize();
	}

	initialize(){
		let self = this;

		this.defaultSort = '-created_at';
		this.sortOptions = [
			{
				'value': '-created_at',
				'label': "Par annonces les plus récentes"
			},
			{
				'value': 'created_at',
				'label': "Par annonces les plus anciennes"
			},
			{
				'value': 'price',
				'label': "Par prix croissant"
			},
			{
				'value': '-price',
				'label': "Par prix décroissant"
			},
            {
                'value': 'date_of_sale',
                'label': "Par date de vente croissante"
            },
            {
                'value': '-date_of_sale',
                'label': "Par date de vente décroissante"
            }
		];

		if(this.$formContainer){
			this.form = new Form({
				'before': this.onBeforeSubmit,
				'$container': this.$formContainer,
				'draw': this.drawResult,
				'onClickExtend': this.onClickExtend,
				'sort': this.defaultSort,
			});
		}

		if(this.$formFullContainer){
			this.formFull = new Form({
				'before': this.onBeforeSubmit,
				'$container': this.$formFullContainer,
				'draw': this.drawResult,
				'sort': this.defaultSort,
			});
		}

		if(this.settings.extend){
			this.showFullForm();
		}

		if(this.$btnCollapseForm.length > 0){
			this.$btnCollapseForm.on('click',function(){
				self.onClickCollapseForm();
			});
		}

		if(this.$btnCloseFullForm.length > 0){
			this.$btnCloseFullForm.on('click',function(){
				self.hideFullForm();
			})
		}

		if(this.settings.result){
			this.drawResult(this.form,this.settings.result);
		}

	}

	onClickExtend(event){
		this.formFull.mergeWith(this.form);
		this.showFullForm();
	}

	showFullForm(){
		this.$formFullContainer.addClass('show');
		$("body").css('overflow','hidden');
	}

	hideFullForm(){
		this.$formFullContainer.removeClass('show');
		$("body").css('overflow','auto');
	}

	onClickCollapseForm(){
		if(this.$container.hasClass('form-opened')){
			this.$container.removeClass('form-opened');
			this.$formContainer.removeClass('show');
		}else{
			this.$container.addClass('form-opened');
			this.$formContainer.addClass('show');
		}
	}

	drawResult(form,result){

		let list = new List({
			'form': form,
			'result': result,
			'sortOptions': this.sortOptions,
		});

		this.$resultContainer
		.html('')
		.append(list.render());
	}

	onBeforeSubmit(form){
		this.hideFullForm();

		if(form.isFullForm()){
			this.form.mergeWith(this.formFull);
		}

		this.$resultContainer
		.html('')
		.append(window.App.renderLoader());
	}

}

module.exports = View;

