
const ContactForm = require('app/ui/contact/Form');

class Form extends ContactForm {

    defaultOptions(){
        return Object.assign(super.defaultOptions(),{});
    }

    initialize() {
        super.initialize();

    }
}

module.exports = Form;
