const Pagination = require('app/ui/Pagination');

class List {

    defaultOptions() {
        return {
            'form': null,
            'result': null,
            'sortOptions': null,//[{'value' : '+price', 'label': 'Par prix croissant'}, {'value': '-price', 'value': 'Par prix décroissant'}]
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onSortChange = this.onSortChange.bind(this);
        this.onExtendedResult = this.onExtendedResult.bind(this);
        this.onClickPaginationLink = this.onClickPaginationLink.bind(this);

        this.$container = $('<div/>')
            .addClass('ad-search-result-list');

        this.$header = $('<div/>')
            .addClass('ad-search-result-list-header');

        this.$body = $('<div/>')
            .addClass('ad-search-result-list-body');

        this.result = this.settings.result;
        this.view = this.settings.view;
        this.form = this.settings.form;

        this.initialize();
    }

    initialize() {

        this.$container
            .append(this.$header)
            .append(this.$body);

        this.fillHeader();
        this.fillBody();


        let total = this.result.meta.total;
        if (total < 3) {

            let except = [];
            if (total > 0) {
                for (let i in this.result.data) {
                    let item = this.result.data[i];
                    except.push(item.id);
                }
            }

            this.$extendedContainer = $('<div/>')
                .append(window.App.renderLoader());
            this.$body.append(this.$extendedContainer);
            this.form.submitExtended(this.onExtendedResult, except);
        }
    }

    fillHeader() {
        let total = this.result.meta.total;
        let labelTransaction = this.form.getLabelTransaction();
        let labelLocalisation = this.form.getLabelLocalisation();

        let title = total;
        title += total > 1 ? " biens immobiliers " : " bien immobilier ";
        title += labelTransaction;
        title += ' ' + labelLocalisation;
        this.$title = $('<h1/>')
            .addClass('ad-search-result-list-title')
            .append(title);


        if (total > 0) {
            this.$sortSelect = $('<select/>')
                .attr('name', 'sort')
                .addClass('ad-search-result-list-sort')
                .on('change', this.onSortChange);

            for (let i in this.settings.sortOptions) {
                let option = this.settings.sortOptions[i];
                let value = option.value;
                let label = "Trier : " + option.label;
                let $option = $('<option/>')
                    .val(value)
                    .append(label);

                if (this.form.sort == value) {
                    $option.attr('selected', 'selected');
                }
                this.$sortSelect.append($option);
            }
        }


        this.$header
            .append(this.$title)

        if (total > 0) {
            this.$header.append(
                $('<div/>')
                    .addClass('float-end')
                    .append(
                        $("<div/>")
                            .addClass("select-box")
                            .append(this.$sortSelect)
                    )
            )
        }
    }


    fillBody() {
        let $list = $('<ul/>')
            .addClass('property-list');

        $list.attr('id', 'result-list');

        if (this.result.data.length > 0) {
            for (let i in this.result.data) {
                let item = this.result.data[i];

                $list.append(
                    $('<li/>')
                        .addClass('property-list-item')
                        .append(item.html)
                )
            }
            this.$body.append($list);

            this.drawPagination();
        } else {
            let $emptyMessage = $('<div/>')
                .addClass('alert alert-warning')
                .append("Aucun résultat ne correspond à votre recherche.");

            this.$body.append($emptyMessage);
        }

    }

    drawPagination() {
        let pagination = new Pagination({
            'pageLinks': this.result.meta.links,
            'onClickLink': this.onClickPaginationLink,
        });

        this.$body.append(pagination.render());
    }

    render() {
        return this.$container;
    }

    onSortChange(event) {
        this.form.onSortChange(this.$sortSelect.val());
    }

    onExtendedResult(form, result) {
        this.$extendedContainer.html('');

        let meta = result.meta;
        let total = meta.total;
        if (total == 0) {
            //@todo what can we do if even extended return no result
            return
        }

        let label = total > 1 ? "Ces biens proches de votre recherche pourraient vous intéresser" : "Ce bien proche de votre recherche pourrait vous intéresser";
        let $extendedList = $('<div/>')
            .addClass('ad-search-result-extended-list')
            .append(
                $('<div/>')
                    .addClass('ad-search-result-extended-list-header')
                    .append(
                        $('<h2/>')
                            .addClass('ad-search-result-extended-list-title')
                            .append(label)
                    )
            )

        let $list = $('<ul/>')
            .addClass('property-list');
        if (result.data.length > 0) {
            for (var i in result.data) {
                var item = result.data[i];

                $list.append(
                    $('<li/>')
                        .addClass('property-list-item')
                        .append(item.html)
                )
            }
        }

        $extendedList.append($list);

        this.$extendedContainer.append($extendedList);
    }

    onClickPaginationLink(link, $link, pagination) {
        this.form.setPage(link.page);
        this.form.submit();
    }

}

module.exports = List;
