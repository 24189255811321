const Slider = require('app/ui/Slider');

class Block extends Slider {

    defaultOptions() {
        return Object.assign(super.defaultOptions(), {});
    }

}

module.exports = Block;
