class Form {

    defaultOptions() {
        return {
            '$container': null,
            'formSelector': '.form',
            'before': null,//function(form){},
            'done': null,//function(form,response, textStatus, jqXhr){},
            'error': null,//function(form,response, textStatus, jqXhr){},
            'after': null,//function(form){},

            'resetOnHidden': true,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onShown = this.onShown.bind(this);
        this.onHidden = this.onHidden.bind(this);
        this.onInvalid = this.onInvalid.bind(this);

        this.$container = this.settings.$container;
        if (this.$container === null || this.$container.length <= 0) {
            window.notify("missing $container settings for app/ui/Form");
        }

        this.$form = $(this.settings.formSelector, this.$container);
        this.$btnSubmit = $('.btn-submit', this.$container);

        this.initialize();
    }

    initialize() {
        window.applyVisibilityObserver(this.$container, this.onShown, this.onHidden);

        this.$btnSubmit.on('click', this.onSubmit);
        this.$form.on('submit', this.onSubmit);
        this.$form.on('keyup change paste', 'input, select, textarea', this.onChange);
    }

    onShown() {
        this.visible = true;
    }

    onHidden() {
        this.visible = false;
        if (this.settings.resetOnHidden) {
            this.reset();
        }
    }

    reset() {
        const formInfo = document.getElementById('form-info')
        if (formInfo) {
            formInfo.remove()
            this.$form
                .removeClass('was-validated')
                .show();
        }

    }

    isValid() {
        let formObject = this.$form[0];

        let validity = formObject.checkValidity();
        this.$form.addClass('was-validated');

        if (validity === false) {
            return false;
        }
        if (window.location.pathname === '/move-challenge') {
            const email = document.getElementById('fromEmail')
            const splitEmail = email.value.split('@')
            const domaineEmail = splitEmail[splitEmail.length - 1]
            if (domaineEmail === 'bskimmobilier.com') {
                const formInfo = document.getElementById('form-info')
                if (formInfo) {
                    formInfo.remove()
                }

                let divParent = email.parentNode
                let infoDiv = document.createElement('div');
                infoDiv.setAttribute('id', 'form-info');
                infoDiv.innerHTML = 'Vous faites déjà partie de la famille ! Pas besoin de remplir le formulaire.'
                divParent.appendChild(infoDiv);

                return false;
            }
        }

        this.disabledButton()

        return true;
    }

    onInvalid() {

    }

    submit() {
        this.$form.trigger('submit');
    }

    onSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        if (!this.isValid()) {
            this.onInvalid();
            return;
        }

        this.onBeforeSubmit();
    }

    onBeforeSubmit() {
        if (this.settings.before) {
            this.settings.before(this);
        }

        return this.doSubmit();
    }

    onChange(e) {
        this.$form.removeClass('was-validated');

        const formInfo = document.getElementById('form-info')
        if (formInfo) {
            formInfo.remove()
        }

        //reset customValidity message in order to be able to call isValid again
        $('input,textarea,select', this.$form).each(function () {
            let $element = $(this);
            $element[0].setCustomValidity('');
        });
    }

    onDone(response, textStatus, jqXhr) {

        if (this.settings.done) {
            this.settings.done(this, response, textStatus, jqXhr);

        }  else {
            $('.form-lead-b2b').hide()
        }
    }



    onFail(response, textStatus, jqXhr) {
        if (this.settings.error) {
            this.settings.error(this, response, textStatus, jqXhr);
        }

    }

    onAfter() {
        new lazyload(document.querySelectorAll('.lazy'));

        if (this.settings.after) {
            this.settings.after(this);
        }
    }

    getData(hasFormData) {
        if (hasFormData) {
            return new FormData(this.$form[0]);
        }
        return this.$form.serializeArray();
    }

    doSubmit() {
        let self = this;

        let url = this.$form.attr('action');
        let method = this.$form.attr('method');
        let data = this.getData();


        $.ajax({
            'url': url,
            'method': method,
            'data': data,
            //@todo improvement handle multipart/data (if necessary)
        })
            .done(function (response, textStatus, jqXhr) {
                self.onDone(response, textStatus, jqXhr);
            })
            .fail(function (jqXhr, textStatus) {
                let response = jqXhr.responseText;
                self.onFail(response, textStatus, jqXhr);
            })
            .always(function () {
                self.onAfter();
            });
    }

    disabledButton() {
        const formButton = document.getElementById('btn-modal-contact');
        if (formButton) {
            formButton.setAttribute('disabled', 'false')
            formButton.style.color = 'lightgray'
            formButton.style.background = 'transparent'
            formButton.style.border = '1px solid lightgray'
        }
    }
}

module.exports = Form;
