class ModalSlider {

    defaultOptions = () => {
        return {
            '$openSliderModal': null,
            '$openSliderModalImg0': null,
            '$openSliderModalImg1': null,
            '$openSliderModalImg2': null,
            '$slidePrev': null,
            '$slideNext': null,
            '$sliderModal': null,
            '$closeIcon': null,
            '$slides': null,
            '$dragImg': null
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$openSliderModal = this.settings.$openSliderModal;
        this.$openSliderModalImg0 = this.settings.$openSliderModalImg0;
        this.$openSliderModalImg1 = this.settings.$openSliderModalImg1;
        this.$openSliderModalImg2 = this.settings.$openSliderModalImg2;
        this.$slidePrev = this.settings.$slidePrev;
        this.$slideNext = this.settings.$slideNext;
        this.$sliderModal = this.settings.$sliderModal;
        this.$closeIcon = this.settings.$closeIcon;
        this.$slides = this.settings.$slides;
        this.$dragImg = this.settings.$dragImg;

        this.body = $('body')

        this.slideIndex = 0;
        this.mouseDown = false;
        this.startX = 0;
        this.endX = 0;
        this.imgCopy = null;
        this.selectedImage = null;

        this.$openSliderModal.on('click', () => this.openImageInModal(0));
        this.$openSliderModalImg0.on('click', () => this.openImageInModal(0));
        this.$openSliderModalImg1.on('click', () => this.openImageInModal(1));
        this.$openSliderModalImg2.on('click', () => this.openImageInModal(2));

        this.$slidePrev.on('click', () => this.updateCurrentSlide(-1))
        this.$slideNext.on('click', () => this.updateCurrentSlide(1))

        $(window).mousedown((e) => {
            this.mouseDown = true;
            this.startX = e.clientX;
        });

        $(window).mouseup((e) => {
            this.endX = e.clientX;
            this.swipe();
            this.mouseDown = false;
        });

        $(window).on('touchstart', (e) => {
            this.mouseDown = true;
            this.startX = e.originalEvent.touches[0].clientX;
        });

        $(window).on('touchend', (e) => {
            this.endX = e.originalEvent.changedTouches[0].clientX;
            this.swipe();
            this.mouseDown = false;
        });

        $(window).on("mousemove", (e) => {
            if (!this.mouseDown) return;
            e.preventDefault();
        });

        $(window).keydown((event) => this.eventKeyDown(event))

        this.$dragImg.each((index, img) => this.dragImage(img));
    }

    openImageInModal = (index) => {
        this.$sliderModal.css('display', "block");
        this.body.css('overflow', 'hidden');

        this.slideIndex = index;
        this.showSlides();

        this.$closeIcon.on('click', this.closeModal)

        this.$sliderModal.on('click', (event) => this.ChecksClickedInEmptySpace(event))
    }

    showSlides = () => {
        this.$slides.each((index, slide) => $(slide).css('display', 'none'));

        this.slideIndex++;
        if (this.slideIndex > this.$slides.length) this.slideIndex = 1

        this.$slides.eq(this.slideIndex - 1).css('display', "flex");
    }

    updateCurrentSlide = (n) => {
        let new_index = this.slideIndex + n - 1;

        if (new_index >= this.$slides.length) new_index = 0;
        else if (new_index < 0) new_index = this.$slides.length - 1;

        this.$slides.each((index, slide) => $(slide).css('display', 'none'));

        this.$slides.eq(new_index).css("display", "flex");
        this.slideIndex = new_index + 1;
    }


    dragImage = (imgElement) => {
        let $imgElement = $(imgElement);

        $(document).mouseup(() => this.removeDuplicateImg());

        $imgElement.mousedown((e) => {
            e.preventDefault();
            this.duplicateImgMouseClicked(e, $imgElement)
        });

        $imgElement.on("dragstart", () => false);

        $(document).on("mousemove", (e) => {
            if (this.selectedImage) {
                this.moveCopyImgToCursor(e);
            }
        });
    }

    duplicateImgMouseClicked = (e, img) => {
        this.imgCopy = img.clone();
        this.imgCopy.css({
            "height": '200px',
            "width": 'auto',
            "position": 'absolute',
            "zIndex": 10001,
            "opacity": 0.4
        });

        this.body.append(this.imgCopy);

        this.selectedImage = this.imgCopy;
        this.moveCopyImgToCursor(e);
    }

    removeDuplicateImg = () => {
        if (this.imgCopy) {
            this.imgCopy.remove();
            this.imgCopy = null;
            this.selectedImage = null;
        }
    }

    ChecksClickedInEmptySpace = (event) => {
        let spans = this.$sliderModal.find("span");
        let isSpanClicked = Array.from(spans).some(span => span.contains(event.target));

        if (event.target === this.$sliderModal.find("img") || isSpanClicked) return;

        this.closeModal()
    }

    moveCopyImgToCursor = (e) => {
        this.selectedImage.css({
            "left": e.pageX - this.selectedImage.width() / 2 + 'px',
            "top": e.pageY - this.selectedImage.height() / 2 + 'px'
        });
    };

    eventKeyDown = (event) => {
        if (this.$sliderModal.css('display') === 'block') {
            if (event.key === "Escape") this.closeModal();
            else if (event.which === 37) this.updateCurrentSlide(-1);
            else if (event.which === 39) this.updateCurrentSlide(1);
            event.preventDefault();
        }
    }

    swipe = () => {
        if (this.mouseDown && Math.abs(this.startX - this.endX) >= 50) {
            if (this.startX < this.endX) this.updateCurrentSlide(-1);
            else this.updateCurrentSlide(1);
        }
    }

    closeModal = () => {
        this.$sliderModal.css('display', "none");
        this.body.css('overflow', 'inherit');
        this.slideIndex = 0;
        this.mouseDown = false;
        this.startX = 0;
        this.endX = 0;
        this.imgCopy = null;
        this.selectedImage = null;
    }
}

module.exports = ModalSlider;
