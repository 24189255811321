class Checkable {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onChange = this.onChange.bind(this);

        this.$container = this.settings.$container;
        if(this.$container === null || this.$container.length <= 0){
        	window.notify("missing $container settings for app/ui/input/Checkable");
        }

        this.$input = $('.checkable-input',this.$container);

        this.initialize();
    }

    initialize(){
        this.$input.on('change',this.onChange);
    }


    onChange(){
        if(this.$input.is(':checked')){
            this.$container.addClass('active');
        }else{
            this.$container.removeClass('active');
        }
    }

}

module.exports = Checkable;
