class Location {

    defaultOptions() {
        return {
            '$container': null,
            'onAcceptGeolocalisation': function () {
            },
            'onRefuseGeolocalisation': function () {
            },
            'onAcceptForAroundMeIfEmpty': false,
            'type': 'all',
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onAutocomplete = this.onAutocomplete.bind(this);
        this.onAcceptGeolocalisation = this.onAcceptGeolocalisation.bind(this);
        this.onRefuseGeolocalisation = this.onRefuseGeolocalisation.bind(this);
        this.onLeaveFocus = this.onLeaveFocus.bind(this);

        this.$container = this.settings.$container;
        if (this.$container === null || this.$container.length <= 0) {
            window.notify("missing $container settings for app/ui/input/Location");
        }

        this.$searchInput = $('.location-input-search', this.$container);
        this.$typeInput = $('.location-input-type', this.$container);
        this.$cityInput = $('.city-location-input-type', this.$container);
        this.$valueInput = $('.location-input-value', this.$container);

        this.$propositionContainer = $('.location-proposition-container', this.$container);

        this.url = this.$container.data('url');
        this.method = this.$container.data('method');

        this.labelForList = this.$searchInput.val();
        if (this.labelForList) {
            this.labelForList = 'sur ' + this.labelForList;
        }

        this.initialize();
    }

    initialize() {
        let self = this;

        this.$loader = App.renderLoader();
        this.$propositionList = $('<ul/>')
            .addClass('location-proposition-list')
            .hide();

        this.$propositionContainer
            .append(this.$loader)
            .append(this.$propositionList);

        window.bindAutocomplete(this.$searchInput, this.onAutocomplete, 500);
        window.applyGeolocationWatcher(this.onAcceptGeolocalisation, this.onRefuseGeolocalisation);

        this.$container.on('focusout', this.onLeaveFocus);

        $("body").on("click", ".validate-search-location", function () {
            self.clearPropositions();
            return false;
        });

        this.cancelPropositionList()

    }

    setLabelForList(label) {
        this.labelForList = label;
        return this;
    }

    getLabelForList() {
        return this.labelForList;
    }

    onAutocomplete() {
        if (this.$searchInput.val() !== '') {
            this.doSearch();
        } else {
            this.clearPropositions();
        }
    }

    onAcceptGeolocalisation() {
        let $inputAroundMe = $('.location-proposition-list-item-aroundme', this.$container);
        if ($inputAroundMe.length > 0) {
            $inputAroundMe.show();
        }

        if (this.settings.onAcceptForAroundMeIfEmpty) {
            this.forceAroundMeIfEmpty();
        }
        this.settings.onAcceptGeolocalisation();
    }

    onRefuseGeolocalisation() {
        let $inputAroundMe = $('.location-proposition-list-item-aroundme', this.$container);
        if ($inputAroundMe.length > 0) {
            $inputAroundMe.hide();
        }
        this.forceClearIfAroudMe();
        this.settings.onRefuseGeolocalisation();
    }

    doSearch() {
        let self = this;

        this.onBefore();

        $.ajax({
            'url': this.url,
            'method': this.method,
            'data': {
                'search': this.$searchInput.val(),
                'type': this.settings.type,
            }
        })
            .done(function (response, textStatus, jqXhr) {
                self.onDone(response, textStatus, jqXhr);
            })
            .fail(function (jqXhr, textStatus, errorThrown) {
                let response = jqXhr.responseText;
                self.onFail(response, textStatus, jqXhr);
            })
            .always(function () {
                self.onAfter();
            });

    }

    onLeaveFocus(event) {
        //@todo maybe improve this, some wierd behavior can happend
        if (this.$propositionContainer.is(':visible')) {
            if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
                this.$propositionContainer.hide();
                if (this.$typeInput.val() == '') {
                    this.$searchInput.val('');
                }
            }
        }
    }

    onBefore() {
        this.$propositionContainer.show();
        this.$loader.show();
        this.$propositionList.html('');

        this.$typeInput.val('');
        this.$valueInput.val('');
        this.labelForList = null;
    }

    onDone(response, textStatus, jqXhr) {

        let data = response['data'];

        let $inputAroundMe = this.drawAroundMeItem();

        $inputAroundMe.hide();
        if (window.getCookie('bsk_allow_geolocalisation')) {
            $inputAroundMe.show();
        }

        let cities = data.cities;
        for (let c in cities) {
            let city = cities[c];

            let $item = this.drawCityItem(city);

            this.$propositionList.append($item);
        }

        let departments = data.departments;
        for (let d in departments) {
            let department = departments[d];

            let $item = this.drawDepartmentItem(department);
            this.$propositionList.append($item);
        }

        if (this.$propositionContainer.hasClass('multiple')) {
            this.$propositionList.append('<li class="validate-search-location-list-item"><a href="#" class="validate-search-location">Valider la recherche</a></li>');
        }

    }

    onFail(response, textStatus, jqXhr) {

    }

    onAfter() {
        this.$loader.hide();
        this.$propositionList.show();
    }


    drawItem(name, type, value, title) {
        let self = this;


        let $item = $('<li/>')
            .addClass('location-proposition-list-item location-proposition-list-item-' + type)
            .attr('data-value', value)
            .attr('data-type', type)
            .attr('data-label', name)
            .attr('data-title', title)

        if (type != 'department') {
            $item.append(name);
        } else {
            $item.append(value + ' ' + name);
        }

        if (type == 'department' && self.$propositionContainer.hasClass('multiple')) {
            $item.prepend(
                $("<span/>")
                    .addClass('clickable-corner')
            );
        }

        $item.on('click', function (event) {
            if (type == 'department' && self.$propositionContainer.hasClass('multiple')) {
                self.onClickPropositionItemDepartment($item);
            } else {
                self.onClickPropositionItem($item);
            }
        });

        return $item;
    }

    drawAroundMeItem() {
        return this.drawItem("Autour de moi", 'aroundme', true, "autour de vous");
    }

    drawCityItem(item) {
        let name = item.name + ' (' + item.zipCode + ')';
        let value = JSON.stringify({
            'longitude': item.longitude,
            'latitude': item.latitude,
            'id': item.id
        });
        return this.drawItem(name, 'coordinates', value, "sur " + item.name);
    }

    drawDepartmentItem(item) {
        let name = item.name;
        let value = item.id;
        return this.drawItem(name, 'department', value, "en " + name);
    }

    onClickPropositionItem($item) {

        let label = $item.data('label');
        let type = $item.data('type');
        let value = $item.data('value');
        let title = $item.data('title');

        if (typeof value == 'object') {
            value = JSON.stringify(value);
        }

        if (value == 'clear') {
            value = '';
            type = '';
            label = '';
            title = '';
        }

        this.$typeInput.val(type);
        this.$cityInput.val('city');
        this.$valueInput.val(value);
        this.$searchInput.val(label);
        this.labelForList = title;

        this.clearPropositions();
    }

    onClickPropositionItemDepartment($item) {
        let type = $item.data('type');
        $item.toggleClass('active');

        this.$typeInput.val(type);
        this.$cityInput.val('city');
        let valueInputVar = '';
        let searchInputVar = '';
        $(".location-proposition-list-item-" + type + ".active").each(function () {
            let value = $(this).data('value');
            let label = $(this).data('label');

            if (typeof value == 'object') {
                value = JSON.stringify(value);
            }
            if (valueInputVar != '')
                valueInputVar = valueInputVar + ',';
            valueInputVar = valueInputVar + value;

            if (searchInputVar != '')
                searchInputVar = searchInputVar + ', ';
            searchInputVar = searchInputVar + label
        });
        this.$valueInput.val(valueInputVar);
        this.$searchInput.val(searchInputVar);
    }

    clearPropositions() {
        this.$propositionContainer.hide();
        this.$propositionList.html('');
    }

    reset() {
        this.$typeInput.val('');
        this.$valueInput.val('');
        this.$searchInput.val('');
        this.labelForList = null;
    }

    forceAroundMeIfEmpty() {
        if (!this.getType()) {
            let $item = this.drawAroundMeItem();
            this.onClickPropositionItem($item);
        }
    }

    forceClearIfAroudMe() {
        if (this.getType() == 'aroundme') {
            this.reset();
        }
    }

    getType() {
        return this.$typeInput.val();
    }


    cancelPropositionList() {
        document.addEventListener('click', function clickOutside(event) {
            let get = document.getElementById('location-proposition-container');

            if (!get.contains(event.target)) {
                get.style.display = 'none';
            }

        });

        $(document).keyup(function (e) {
            if (e.key === "Escape") {
                let get = document.getElementById('location-proposition-container');
                get.style.display !== 'none' ? get.style.display = 'none' : undefined;
            }
        });
    }
}

module.exports = Location;
