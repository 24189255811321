
class Pagination {

    defaultOptions() {
        return {
            'pageLinks': null,
            'prevLink': null,
            'nextLink': null,

            'onClickLink': function(link,$link,pagination){}
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = $('<div/>')
        .addClass('pagination');


        this.pageLinks = this.settings.pageLinks;
        this.prevLink = this.settings.prevLink;
        this.nextLink = this.settings.nextLink;

        this.initialize();
    }

    initialize(){
        this.build();
    }

    build(){
        this.$list = $('<ul/>')
        .addClass('pagination-list');

        if(this.prevLink){
            this.$btnPrevious = this.buildLink(this.prevLink);

            this.$list.append(
                $('<li/>')
                .addClass('pagination-list-item')
                .append(this.$btnPrevious)
            );
        }

        if(this.pageLinks){
            for(var i in this.pageLinks){

                let link = this.pageLinks[i];
                let $link = this.buildLink(link);
                this.$list.append($link);
            }
        }


        if(this.nextLink){
            this.$btnNext = this.buildLink(this.nextLink);

            this.$list.append(
                $('<li/>')
                .addClass('pagination-list-item')
                .append(this.$btnNext)
            );
        }

        this.$container.append(this.$list);

    }

    buildLink(link){
        let self = this;

        let $link = $('<li/>')
        .addClass('pagination-list-item');

        let $btn = $('<button/>')
        .attr('type','button')
        .addClass('btn btn-'+link.slug)
        .append(link.label)

        if(link.active){
            $link.addClass('active');
        }

        if(link.page == null){
            $btn.attr('disabled','disabled');
        }

        $btn.on('click',function(event){
            event.stopPropagation();
            event.preventDefault();
            self.onClickLink(link,$link);
        });

        $link.append($btn);

        return $link;
    }

    render(){
        return this.$container;
    }

    onClickLink(link,$link){
        this.settings.onClickLink(link,$link,this);
    }
}

module.exports = Pagination;
