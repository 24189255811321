

class Modal{

    defaultOptions() {
        return {
            '$modal': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onClickModal = this.onClickModal.bind(this);
        this.onClose = this.onClose.bind(this);

        this.$modal = this.settings.$modal;

        this.$btnClose = $('.close',this.$modal);
        this.$btnCancel = $('.btn-cancel',this.$modal);

        this.initialize();
    }

    initialize(){

        this.$modal.on('click',this.onClickModal);
        this.$btnClose.on('click',this.onClose);
        this.$btnCancel.on('click',this.onClose);
    }

    show(){
        this.$modal.addClass('show');
    }

    hide(){
        this.$modal.removeClass('show');
    }

    toggle(){
        this.$modal.toggleClass('show');
    }

    onClose(event){
        this.hide();
    }

    onClickModal(event){
        let $target = $(event.target);
        if($target.hasClass('modal') && !$target.hasClass('static')){
            this.onClose(event);
        }
    }
}

module.exports = Modal;
