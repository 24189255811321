const Pagination = require('app/ui/Pagination');

class List {

    defaultOptions() {
        return {
        	'view': null,
        	'result': null,
            'sortOptions': null,//[{'value' : '+price', 'label': 'Par prix croissant'}, {'value': '-price', 'value': 'Par prix décroissant'}]
            'length': 12,
            'lengthOptions': null,//[{'value' : 12, 'label': '12 par pages'}, {'value' : 24, 'label': '24 par pages'}]
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onClickPaginationLink = this.onClickPaginationLink.bind(this);
        this.onChangeLength = this.onChangeLength.bind(this);

        this.$container = $('<div/>')
        .addClass('agent-search-result-list');

        this.$header = $('<div/>')
        .addClass('agent-search-result-list-header');

        this.$body = $('<div/>')
        .addClass('agent-search-result-list-body');

        this.result = this.settings.result;
        this.view = this.settings.view;
        this.length = this.settings.length;
        this.lengthOptions = this.settings.lengthOptions;

        this.initialize();
    }

    initialize(){

        this.$container
        .append(this.$header)
        .append(this.$body);

        this.fillHeader();
        this.fillBody();
    }

    fillHeader(){

        this.$title = $('<h1/>')
        .addClass('agent-search-result-list-title')
        .append(
            $('<span/>')
            .addClass('fs-bold')
            .append("Nos conseillers au")
        )
        .append(' ')
        .append(
            $('<span/>')
            .addClass('text-primary')
            .append("plus proche de vous")
        );

        this.$header
        .append(this.$title)
    }


    fillBody(){
        let $list = $('<ul/>')
        .addClass('agent-list');
        if(this.result.data.length > 0){
            for(var i in this.result.data){
                var item = this.result.data[i];

                $list.append(
                    $('<li/>')
                    .addClass('agent-list-item')
                    .append(item.html)
                )
            }
            this.$body.append($list);

            this.drawPagination();
            this.drawLengthSelector();
        }else{
            let $emptyMessage = $('<div/>')
            .addClass('alert alert-warning')
            .append("Aucun conseiller ne correspond à votre recherche.");
            this.$body.append($emptyMessage);
        }
    }

    drawPagination(){
        let prevLink = this.result.links.prev;
        let nextLink = this.result.links.next;
        if(this.result.meta.last_page == 1){
            return;
        }
        let pagination = new Pagination({
            'pageLinks': this.result.meta.links,
            'onClickLink': this.onClickPaginationLink,
        });

        this.$body.append(pagination.render());
    }

    drawLengthSelector(){
        if(this.lengthOptions){

            this.$selectLength = $('<select/>')
            .addClass('select length-select');

            for(var i in this.lengthOptions){
                let option = this.lengthOptions[i];

                let $option = $('<option/>')
                .val(option.value)
                .append(option.label);

                if(option.value == this.length){
                    $option.attr('selected','selected');
                }
                this.$selectLength.append($option);
            }
            this.$selectLength.on('change',this.onChangeLength);

            this.$body.append(
				$("<div/>")
				.addClass("length-select-box")
				.append(this.$selectLength)
			);

        }
    }

    onClickPaginationLink(link,$link,pagination){
        this.view.setPage(link.page);
        this.view.reload();
    }

    onChangeLength(event){
        this.length = this.$selectLength.val();
        this.view.setPage(null);
        this.view.setLength(this.length);
        this.view.reload();
    }

    render(){
        return this.$container;
    }
}

module.exports = List;
