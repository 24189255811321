
const AgentBlock = require('app/b2c/component/agent/Block');
const AdBlock = require('app/b2c/component/ad/Block');

class View {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {

        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;

        this.$agentBlock = $('.agent-block',this.$container);
        this.$adBlock = $('.property-block',this.$container);

        this.initialize();
    }

    initialize(){

        new AgentBlock({
            'from': 'estimate',
            '$container': this.$agentBlock
        });
        new AdBlock({
            'from': 'estimate',
            '$container': this.$adBlock
        });

		this.partnerSwiper();
    }

    partnerSwiper(){
        if($(".partner-block").length > 0){
            new Swiper('.partner-block', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 2,
				loop: true,
				autoplay: {
					delay: 1000,
				  },
                watchSlidesProgress: false,
                breakpoints: {
                    400: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }
}

module.exports = View;
