require('app/common');

const Loader = require('app/ui/Loader');
const Tab = require('app/ui/Tab');
const Modal = require('app/ui/Modal');

class Application {

    defaultOptions() {
        return {
            'routes': [],
            'bookmarkLifetime': 366 * 24,//cookie lifetime for bookmark 1year
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        window.App = this;//set singleton instance

        this.modals = {};

        this.initialize();
    }

    get(key) {
        return this.settings[key] ?? null;
    }

    set(key, value) {
        this.settings[key] = value;
        return this;
    }

    url(routeName, params) {

        let route = this.settings.routes[routeName] ?? null;
        if (route === null) {
            return window.notify('Route ' + routeName + ' do not exist', 'error');
        }

        let queryParams = {};
        if (params) {
            let paramsKeys = Object.keys(params);
            for (let i in paramsKeys) {
                let key = paramsKeys[i];
                let value = params[key];

                let targetKey = "__" + key.toUpperCase() + "__";

                if (route.includes(targetKey)) {
                    route = route.replace(targetKey, value);
                } else {
                    queryParams[key] = value;
                }
            }
        }

        let queryParamsString = window.objectToUrlParameters(queryParams);

        console.log(route + queryParamsString);

        return route + queryParamsString;
    }

    initialize() {

        this.initializeMobile();
        this.initTabs();
        this.initializeBookmarks();
        this.initializeScroll();
        this.initializeModals();
        this.mobileReview();
        this.onJobCarrousel();
        this.onPricingToggle();
        this.fancyScroll();
        this.playVideoLandingPage();
        this.sliderImageOneColumnsText();
        this.sliderVideoModal();
        this.changeVideoModal();
        this.openVideoModal();
        this.loadTestimonialsVideos();
        this.sliderClients();
        this.moveChallengeBanner();
    }

    fancyScroll() {

        $("body a").on("click", function (event) {
            let $btn = $(this);
            let target = $btn.attr('href');
            if (target.indexOf("#") === 0 && !$btn.hasClass('btn-modal')) {
                let $target = $(target);
                if ($target.length > 0) {
                    $('html, body').stop().animate({
                        scrollTop: ($(target).offset().top - 120),
                    }, 400);
                    event.preventDefault();
                }
            }
        });

        let hash = window.location.hash.substr(1);
        if (hash.length > 0) {
            let div_hash = $('#' + hash);

            if ((typeof div_hash !== 'undefined') && div_hash !== 'null')
                if (div_hash.length > 0)
                    $('html, body').stop().animate({
                        scrollTop: ($("#" + hash).offset().top - 120),
                    }, 400);
        }
    }

    notify(message, level, data, delay) {
        if (!data) {
            data = null;
        }

        switch (level) {
            case 'error':
            case 'err':
            case 'danger':
                data ? console.error(message, data) : console.error(message);
                break;

            case 'warning':
            case 'warn':
                data ? console.warn(message, data) : console.warn(message);
                break;

            case 'success':
            case 'info':
            default:
                //no need to flood the console with success or info
                //data ? console.log(message,data) : console.log(message);
                break;
        }

        //@todo develop a way to show notification to user
    }

    initializeMobile() {
        const $body = $("body");
        $body.on("click", ".mobile-menu_opener", function () {
            const $header = $("header");
            if ($header.hasClass('active-mobile')) {
                $header.removeClass('active-mobile');
                $("html").css('overflow', 'auto');
            } else {
                $header.addClass('active-mobile');
                $("html").css('overflow', 'hidden');
            }
            return false;
        });

        $body.on("click", ".header-container > .inner-header-container > nav > ul > li > span", function () {
            if (!$(this).parent().hasClass('opened'))
                $(".header-container > .inner-header-container > nav > ul").find("li.opened").removeClass('opened');
            $(this).parent().toggleClass('opened');
            return false;
        });
    }

    renderLoader() {
        let loader = new Loader();
        return loader.render();
    }


    initTabs() {
        $('.tab-container').each(function () {
            let $container = $(this);

            new Tab({
                '$container': $container
            })
        })
    }

    initializeBookmarks() {
        let self = this;

        $('body').on('click', ".btn-bookmark", function (event) {

            event.preventDefault();
            event.stopPropagation();

            let $btn = $(this);
            self.onClickBtnBookmark($btn);

        });
    }

    onClickBtnBookmark($btn) {
        let type = $btn.data('type');
        let value = $btn.data('id');


        let isViewBookmark = $('.view-bookmark').length > 0;

        if ($btn.hasClass('active')) {
            $btn.removeClass('active');
            $btn.find('i').removeClass('active');
            if (isViewBookmark && !confirm("Êtes-vous sur de supprimer ce favori ?")) {
                return;
            }
            this.removeBookmark(type, value);
        } else {
            $btn.addClass('active');
            $btn.find('i').addClass('active');

            this.addBookmark(type, value);
        }
    }

    addBookmark(type, value) {
        let bookmarks = this.getBookmarks(type);

        bookmarks.push(value);

        this.setBookmarks(type, bookmarks);

        let event = new CustomEvent(
            'bsk.bookmark.' + type + '.added',
            {
                "type": type,
                "value": value
            }
        );
        document.dispatchEvent(event);
    }

    removeBookmark(type, value) {
        let bookmarks = this.getBookmarks(type);

        bookmarks = bookmarks.filter(function (item) {
            return item !== value
        })

        this.setBookmarks(type, bookmarks);

        let event = new CustomEvent(
            'bsk.bookmark.' + type + '.removed',
            {
                "type": type,
                "value": value
            }
        );
        document.dispatchEvent(event);
    }

    getBookmarks(type) {
        if (type === null) {
            type = "ad";
        }
        let key = 'bsk_bookmark_' + type;
        let bookmarks = window.getCookie(key);
        if (!bookmarks) {
            bookmarks = [];
        } else {
            bookmarks = JSON.parse(bookmarks);
        }

        return window.uniqueArray(bookmarks);
    }

    setBookmarks(type, bookmarks) {
        if (type === null) {
            type = "ad";
        }
        let key = 'bsk_bookmark_' + type;
        window.setCookie(key, JSON.stringify(bookmarks), this.settings.bookmarkLifetime);
    }

    initializeScroll() {
        document.addEventListener('scroll', function () {
            if (window.scrollY > 0)
                $("body").addClass('scrolled');
            else
                $("body").removeClass('scrolled');
        }, true);

        window.onload = () => {
            if (window.scrollY > 0)
                $("body").addClass('scrolled');
        };

    }

    initializeModals() {
        let self = this;

        $('body').on('click', ".btn-modal", function () {
            $('.form-lead-b2b').show()
            let $btn = $(this);
            let target = $btn.data('target');


            if (!self.modals[target]) {
                let $modal = $(target);

                if (target === "#contactModal") {
                    self.openBullModal(self, target, $modal)
                } else {
                    self.modals[target] = new Modal({
                        '$modal': $modal,
                    });
                }
            }

            if (window.innerWidth > 990) {
                self.modals[target].toggle();
            } else {
                target === "#contactModal" ? self.modals['#contactFullModal'].toggle() :  self.modals[target].toggle();
            }

            return false;
        });

        if ($("#contactSection").length > 0) {
            if (window.location.pathname === '/') {
                setInterval(function () {
                    $("#contactSection").effect("bounce", {times: 3}, 1000);
                }, 25000);
            }
            if (window.innerWidth > 990) {
                const $body = $("body")
                $body.on('click', '#contactModalHand', function () {
                    if (window.innerWidth > 990) {
                        $(this).hide();
                    }
                });
                $body.on('click', '#contactModal .close', function () {
                    $('#contactModalHand').show();
                });
            }

        }

    }

    openBullModal(self, target, $modal) {
        const widthSize = window.innerWidth;

        if (widthSize > 990) {
            self.modals[target] = new Modal({
                '$modal': $modal,
            });

        } else {
            self.modals['#contactFullModal'] = new Modal({
                '$modal': $('#contactFullModal'),
            });
        }

    }

    mobileReview() {
        if (window.innerWidth <= 990 && $(".testimonial_swiper").length > 0) {
            new Swiper('.testimonial_swiper', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.1,
                freeMode: true,
                watchSlidesProgress: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.block-testimonial-swiper-button-next',
                    prevEl: '.block-testimonial-swiper-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 4.5,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }

    sliderClients() {
        if ($(".client_block__list .swiper-slide").length > 0) {
            new Swiper('.client_block__list-main', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 3,
                freeMode: true,
                watchSlidesProgress: true,
                autoplay: {
                    delay: 1500,
                },
                breakpoints: {
                    400: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 5,
                        spaceBetween: 20,
                    },
                    1290: {
                        slidesPerView: 7,
                        spaceBetween: 30,
                    },
                }
            });
        }
    }

    onJobCarrousel() {
        if ($(".job-carrousel-block--main .swiper-slide").length > 0) {
            new Swiper('.job-carrousel-block--main', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.6,
                freeMode: true,
                watchSlidesProgress: true,
                // If we need pagination
                pagination: {
                    el: '.swiper-pagination',
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 4.5,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }

    onPricingToggle() {
        $(".pricing_item .pricing_item-button").click(function () {
            $(this).closest('.pricing_item').toggleClass('active');
        });
    }

    playVideoLandingPage() {
        $(".landingpage-video__main .play_button").click(function () {
            $(".landingpage-video__main .video_image").addClass('active');
            $('.landingpage-video_wrapper').find('.text-before,.text-after').hide();
        });
    }

    sliderImageOneColumnsText() {
        if ($(".block-image-one-columns-text__container .swiper-slide").length > 0) {
            new Swiper('.block-image-one-columns-text__container .swiper', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1,
                freeMode: true,
                watchSlidesProgress: true,
                // Navigation arrows
                navigation: {
                    nextEl: '.tes-button-next',
                    prevEl: '.tes-button-prev',
                },
                breakpoints: {
                    400: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 60,
                    },
                }
            });
        }
    }

    sliderVideoModal() {
        if ($(".list-videos__main .swiper-slide").length > 0) {
            new Swiper('.list-videos__main', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 2,
                freeMode: true,
                watchSlidesProgress: true,

                breakpoints: {
                    400: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                }
            });
        }
    }

    changeVideoModal() {
        let self = this;

        if ($("#videoModal").length > 0) {
            $("#videoModal .video_item.video-modal").click(function () {
                let src = $(this).attr('id-video');
                if (src) {
                    $("#videoModal iframe").attr('src', src);
                }
            });
        }

        let src = null

        $(".video-popup").click(function (e) {

            e.preventDefault();
            src = $(this).attr('id-video');

            if (src) {
                if (src === "https://www.youtube.com/embed/HYESnYSPGoM") {
                    $("#videoModalHome iframe").attr('src', src);
                    let target = '#videoModalHome';

                    if (!self.modals[target]) {
                        let $modal = $(target);

                        self.modals[target] = new Modal({
                            '$modal': $modal,
                        });
                    }

                    self.modals[target].toggle();
                } else {
                    $("#videoModal iframe").attr('src', src);
                    let target = '#videoModal';

                    if (!self.modals[target]) {
                        let $modal = $(target);

                        self.modals[target] = new Modal({
                            '$modal': $modal,
                        });
                    }

                    self.modals[target].toggle();
                }

            }
        });

        $("#videoModalHome .icon-close").click(function (e) {
            e.preventDefault();
            let iframe_tag;

            src === "https://www.youtube.com/embed/HYESnYSPGoM" ?
                iframe_tag = $("#videoModalHome iframe") : iframe_tag = $("#videoModal iframe")

            if (iframe_tag) {
                let iframeSrc = iframe_tag[0].src;
                iframe_tag[0].src = iframeSrc;
            }
        })

        $("#videoModal .icon-close").click(function (e) {
            e.preventDefault();
            let iframe_tag;

            src === "https://www.youtube.com/embed/HYESnYSPGoM" ?
                iframe_tag = $("#videoModalHome iframe") : iframe_tag = $("#videoModal iframe")

            if (iframe_tag) {
                let iframeSrc = iframe_tag[0].src;
                iframe_tag[0].src = iframeSrc;
            }
        })

    }

    openVideoModal() {
        let self = this;

        if ($("#videoModal").length > 0) {
            $(".value_item").click(function () {
                let src = $(this).attr('id-video');

                if (src) {
                    $("#videoModal iframe").attr('src', src);
                }

                let target = '#videoModal';

                if (!self.modals[target]) {
                    let $modal = $(target);

                    self.modals[target] = new Modal({
                        '$modal': $modal,
                    });
                }

                self.modals[target].toggle();

            });
        }

    }

    loadTestimonialsVideos() {
        const $discoverList = $(".discover-list");
        if ($discoverList.length > 0 && $discoverList.find(".discover_button").length > 0) {
            $discoverList.find(".discover_button").click(function () {
                $discoverList.find(".hidden").removeClass('hidden');
                $(this).remove();
                return false;
            });
        }
    }

    isAnalyticEnable() {
        return (typeof dataLayer !== 'undefined');
    }

    moveChallengeBanner() {
        if ($(".block-move-challenge-banner").length > 0 && $(".b2b_home").length > 0) {
            const $blockHeaderHome = $(".block-header-home");
            let pos = ($blockHeaderHome.offset().top + $blockHeaderHome.outerHeight(true));
            $(window).scroll(function () {
                let scroll = $(window).scrollTop();
                if (scroll >= pos)
                    $(".block-move-challenge-banner").addClass('active');
                else
                    $(".block-move-challenge-banner").removeClass('active');
            });
        }
    }
}

module.exports = Application;
