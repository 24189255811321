class TagListSelect {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;
        if(this.$container === null || this.$container.length <= 0){
        	window.notify("missing $container settings for app/ui/input/TagListSelect");
        }

        this.initialize();
    }

    initialize(){
        let self = this;

        this.$select = $('select',this.$container);

        this.$tagList = $('.tag-list',this.$container);
        this.$tagListWrapper = this.$tagList.parent();

        this.$tagListWrapper.on('click',function(event){
            event.preventDefault();
            event.stopPropagation();
            self.toggleList();
        });
        //if click outside (other clicks has a stopPropagation)
        $('body').on('click',function(){
            self.hideList();
        });
        this.$tagList.on('click',function(event){
            event.preventDefault();
            event.stopPropagation();//prevent toggleList to be called by tagListWrapper on click event
        });
        $('.tag',this.$tagList).on('click',function(event){
            event.preventDefault();
            event.stopPropagation();
            self.onClickTag($(this));
        });

        $('.close-tag-list',this.$tagList).on('click',function(event){
            event.preventDefault();
            event.stopPropagation();
            self.toggleList();
        });

        this.setSelectedValues();
    }

    toggleList(){

        this.$tagListWrapper.toggleClass('opened');
    }

    hideList(){
        this.$tagListWrapper.removeClass('opened');
    }

    onClickTag($tag){
        $tag.toggleClass('tag-selected');
        let value = $tag.data('value');
        let $option =  $('option[value="' + value + '"]',this.$select);
        if($option.length <= 0){
            return;
        }

        if($tag.hasClass('tag-selected')){
            $option
            .attr('selected','selected')
            .prop('selected', true);
        }else{
            $option
            .removeAttr('selected')
            .prop('selected', false);
        }
        this.$select.trigger('change');
		this.setSelectedValues();
    }

	setSelectedValues(){
		$(".selected-values").html('');
		if($('.tag-selected',this.$tagList).length > 0){
			$(".default-value").hide();
			$('.tag-selected',this.$tagList).each(function(){
				$(".selected-values").append($("<span/>").append($(this).html()));
			});
		}else{
			$(".default-value").show();
		}
	}

    val(){
        return this.$select.val();
    }

    update(values){
        $('option',this.$select)
        .removeAttr('selected')
        .prop('selected', false);

        $('.tag',this.$tagList)
        .removeClass('tag-selected');

        for(let i in values){
            let value = values[i];

            let $option =  $('option[value="' + value + '"]',this.$select);
            $option
            .attr('selected','selected')
            .prop('selected', true);


            let $tag = $('.tag[data-value="'+value+'"]',this.$tagList);
            $tag.addClass('tag-selected');
        }

        this.$select.trigger('change');
        this.setSelectedValues();
    }

}

module.exports = TagListSelect;
