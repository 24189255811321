
const ReviewBlock = require('app/b2c/component/review/Block');

class View {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {

        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;

        this.$reviewBlock = $('.review-block',this.$container);

        this.initialize();
    }

    initialize(){
        this.reviewBlock = new ReviewBlock({
            'from': 'sell',
            '$container': this.$reviewBlock
        });

    }
}

module.exports = View;
