const Pagination = require('app/ui/Pagination');

class List {

    defaultOptions() {
        return {
        	'form': null,
        	'result': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onClickPaginationLink = this.onClickPaginationLink.bind(this);

        this.$container = $('<div/>')
        .addClass('review-list-container');

        this.form = this.settings.form;
        this.result = this.settings.result;

        this.initialize();
    }

    initialize(){

        this.fill();
    }

    fill(){

        this.$container.html('');

        let $list = $('<ul/>')
        .addClass('review-list');
        if(this.result.data.length > 0){
            for(var i in this.result.data){
                var item = this.result.data[i];

                $list.append(
                    $('<li/>')
                    .addClass('review-list-item')
                    .append(item.html)
                )
            }
            this.$container.append($list);

            this.drawPagination();
        }else{
            let $emptyMessage = $('<div/>')
            .addClass('alert alert-warning')
            .append("Aucun avis ne correspond à votre recherche.");
            this.$container.append($emptyMessage);
        }
    }

    drawPagination(){
        let prevLink = this.result.links.prev;
        let nextLink = this.result.links.next;

        let pagination = new Pagination({
            'prevLink': prevLink,
            'nextLink': nextLink,
            'onClickLink': this.onClickPaginationLink,
        });

        this.$container.append(pagination.render());
    }

    onClickPaginationLink(link,$link,pagination){
        this.form.setPage(link.page);
        this.form.submit();
		$('html, body').animate({
			scrollTop: $(".section-review").offset().top
		}, 800);
    }

    render(){
        return this.$container;
    }
}

module.exports = List;
