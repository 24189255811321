

class Budget {

    defaultOptions() {
        return {
            '$container': null,
            'gapPercent': 5
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;
        if(this.$container === null || this.$container.length <= 0){
        	window.notify("missing $container settings for app/ui/input/Budget");
        }

        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);

        this.$minPriceInput = $('.budget-price-input-min',this.$container);
        this.$maxPriceInput = $('.budget-price-input-max',this.$container);
        this.$minRangeInput = $('.budget-range-input-min',this.$container);
        this.$maxRangeInput = $('.budget-range-input-max',this.$container);

        this.$sliderProgress = $('.budget-slider-progress',this.$container);

        this.min = parseInt(this.sanitizeFormatedVal(this.$minRangeInput.attr('min')));
        this.max = parseInt(this.sanitizeFormatedVal(this.$minRangeInput.attr('max')));
        this.gap = Math.round(((this.max - this.min) * this.settings.gapPercent) / 100);


        this.initialize();
    }

    initialize(){
        this.$minPriceInput.on('input',this.onChangePrice);
        this.$maxPriceInput.on('input',this.onChangePrice);
        this.$minRangeInput.on('input',this.onChangeRange);
        this.$maxRangeInput.on('input',this.onChangeRange);

        this.refreshProgress();
        this.update();

    }

    reset(){
        this.$minPriceInput.val(this.min);
        this.$maxPriceInput.val(this.max);
        this.$minPriceInput.trigger('input');
        this.$maxPriceInput.trigger('input');
    }

    update(){
        this.$minPriceInput.trigger('input');
        this.$maxPriceInput.trigger('input');
    }

    onChangePrice(event){
        let minPrice = this.sanitizeFormatedVal(this.$minPriceInput.val());
        let maxPrice = this.sanitizeFormatedVal(this.$maxPriceInput.val());

        if(!minPrice){
            minPrice = this.min;
        }
        if(!maxPrice){
            maxPrice = this.max;
        }

        let $changedInput = $(event.target);
        if($changedInput.hasClass('budget-price-input-min')){
            this.$minRangeInput.val(minPrice);
        }else{
            this.$maxRangeInput.val(maxPrice);
        }

        if((maxPrice - minPrice >= this.gap) && maxPrice <= this.$maxRangeInput.attr('max')){
            if($changedInput.hasClass('budget-price-input-min')){
                this.$minRangeInput.val(minPrice);
            }else{
                this.$maxRangeInput.val(maxPrice);
            }
        }

        this.refreshProgress();
		this.refreshFormat(this.$minPriceInput);
		this.refreshFormat(this.$maxPriceInput);
    }

    onChangeRange(event){
        let minVal = this.sanitizeFormatedVal(this.$minRangeInput.val());
        let maxVal = this.sanitizeFormatedVal(this.$maxRangeInput.val());

        let $changedInput = $(event.target);

        if((maxVal - minVal) < this.gap){
            if($changedInput.hasClass('budget-range-input-min')){
                this.$minRangeInput.val(maxVal - this.gap);
            }else{
                this.$maxRangeInput.val(minVal + this.gap);
            }
        }else{
            if(maxVal == this.$maxRangeInput.attr('max')){
                this.$maxPriceInput.val('');
            }else{
                this.$maxPriceInput.val(maxVal);
            }

            if(minVal == this.$minPriceInput.attr('min')){
                this.$minPriceInput.val('');
            }else{
                this.$minPriceInput.val(minVal);
            }
        }

        this.refreshProgress();
		this.refreshFormat(this.$minPriceInput);
		this.refreshFormat(this.$maxPriceInput);
    }

    refreshProgress(){
        let minVal = this.$minRangeInput.val();
        let maxVal = this.$maxRangeInput.val();
        this.$sliderProgress.css('left', ((minVal / this.$minRangeInput.attr('max')) * 100) + "%");
        this.$sliderProgress.css('right', 100 - ((maxVal / this.$maxRangeInput.attr('max')) * 100) + "%");
    }

	refreshFormat(ele){

		let $this = ele;

		// Get the value.
		let input = $this.val();

		input = input.replace(/[\D\s\._\-]+/g, "");
		input = input ? parseInt( input, 10 ) : 0;

		$this.val( function() {
			return ( input === 0 ) ? "" : input.toLocaleString( "fr-FR" );
		} );
	}

	sanitizeFormatedVal(valInput){
		let value = valInput.replace(/[($)\s\._\-]+/g, ''); // Sanitize the values.
        value = parseInt(value);
        if(isNaN(value)){
            value = null;
        }
        return value;
	}

}

module.exports = Budget;
