const Slider = require('app/ui/Slider');

class Block extends Slider {

    defaultOptions(){
        let options = {
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        var self = this;

        this.onAcceptGeolocalisation = this.onAcceptGeolocalisation.bind(this);
        this.onRefuseGeolocalisation = this.onRefuseGeolocalisation.bind(this);

        super.initialize();

        window.applyGeolocationWatcher(this.onAcceptGeolocalisation,this.onRefuseGeolocalisation);

        return this;
    }

    onAcceptGeolocalisation(position){
        window.refresh();
    }

    onRefuseGeolocalisation(){
        window.refresh();
    }

    drawItem(item){
        let $item = super.drawItem(item);
        $item.addClass('property-slide-list-item');
        return $item;
    }

}

module.exports = Block;
