const UIForm = require('app/ui/Form');

class Form extends UIForm {

    defaultOptions(){
        let options = {
            'formSelector': '.review-search-form',
        	'$container': null,
        	'draw': function(form,result){},
            'radius': 100,
            'page': null,
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        this.onAcceptGeolocalisation = this.onAcceptGeolocalisation.bind(this);
        this.onRefuseGeolocalisation = this.onRefuseGeolocalisation.bind(this);

        super.initialize();

        this.defaultSort = '-created_at';
        this.distanceSort = 'distance';
        this.sort = null;
        this.page = null;


        this.$departmentSelect = $('.department-select',this.$formContainer);
        this.$departmentSelect.on('change',this.onSubmit);

        window.applyGeolocationWatcher(this.onAcceptGeolocalisation,this.onRefuseGeolocalisation);

        return this;
    }

    onDone(response, textStatus, jqXhr){
    	super.onDone(response, textStatus, jqXhr);

    	this.settings.draw(this,response);
    }

    setPage(page){
        this.page = page;
    }

    onChange(event){
        this.setPage(null);
        super.onChange(event);
    }

    getData(){
        let data = super.getData();

        if(this.sort == this.distanceSort){
            if(this.$departmentSelect.val()){
                this.sort=this.defaultSort;
            }else{
                data.push({
                    'name': 'location[type]',
                    'value': 'aroundme',
                });
                data.push({
                    'name': 'location[value]',
                    'value': true,
                });

            }
        }

        if(this.sort){
            let sort = this.sort;
            data.push({
                'name': 'sort',
                'value': sort,
            });
        }

        if(this.page){
            data.push({
                'name': 'page[number]',
                'value': this.page,
            });

        }

        return data;
    }

    onAcceptGeolocalisation(){
        if(this.sort != this.distanceSort){
            this.sort = this.distanceSort;
            this.submit();
        }
    }

    onRefuseGeolocalisation(){
        if(this.sort != this.defaultSort){
            this.sort = this.defaultSort;
            this.submit();
        }
    }

}

module.exports = Form;
