const Layout = require('app/b2c/agent/page/Layout');

const Form = require('app/b2c/component/agent/ad/Form');
const List = require('app/b2c/component/agent/ad/List');

class Property extends Layout {

    defaultOptions(){
        let options = {
            'length': 6,
            'lengthOptions': null,
            'result': null,//give api result to init pagination at startup
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        this.onBeforeSubmitAd = this.onBeforeSubmitAd.bind(this);
        this.drawResult = this.drawResult.bind(this);

        super.initialize();

        this.length = this.settings.length;

        this.$propertyFormContainer = $('.property-form-container',this.$container);
        this.$propertyResultContainer = $('.property-result-container',this.$container);

        this.form = new Form({
            'before': this.onBeforeSubmitAd,
            '$container': this.$propertyFormContainer,
            'draw': this.drawResult,
            'length': this.length,
        });

        if(this.settings.result){
            this.drawResult(this.form,this.settings.result);
        }

        return this;
    }

    onBeforeSubmitAd(form){
        this.$propertyResultContainer
        .html('')
        .append(window.App.renderLoader());
    }

    drawResult(form,result){

        let list = new List({
            'view': this,
            'result': result,
            'length': this.length,
            'lengthOptions': this.settings.lengthOptions,
        });

        this.$propertyResultContainer
        .html('')
        .append(list.render());
    }

    setPage(page){
        return this.form.setPage(page);
    }

    setLength(length){
        this.length = length;
        return this.form.setLength(this.length);
    }

    reload(){
        this.form.submit();
    }

}

module.exports = Property;
