const Slider = require('app/ui/Slider');

class Block extends Slider {

    defaultOptions(){
        let options = {
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        this.onAcceptGeolocalisation = this.onAcceptGeolocalisation.bind(this);
        this.onRefuseGeolocalisation = this.onRefuseGeolocalisation.bind(this);

        super.initialize();

        this.$agentHighlightName = $('.agent-highlight-name');

        window.applyGeolocationWatcher(this.onAcceptGeolocalisation,this.onRefuseGeolocalisation);

        return this;
    }


    onAcceptGeolocalisation(position){
        window.refresh();
    }

    onRefuseGeolocalisation(){
        window.refresh();
    }

    onChange(event){
        this.updateAgentHighlightName();
    }

    updateAgentHighlightName(){
        this.$agentHighlightName.html('');
        $('.swiper-slide',this.$slideWrapper).removeClass('highlight');
        let $activeItem = this.getFirstActiveItem();
        if($activeItem.length > 0){
            $activeItem.addClass('highlight');
            let firstName = $activeItem.data('firstname');
            this.$agentHighlightName.append(firstName);
        }
    }

}

module.exports = Block;
