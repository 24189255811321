

class Loader{

    defaultOptions() {
        return {
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.initialize();
    }

    initialize(){

    }

	render(){
		return $('<div/>').addClass('loader');
	}
}

module.exports = Loader;