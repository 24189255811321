const AdBlock = require('app/b2c/component/ad/Block');
const ProjectBlock = require('app/b2c/component/project/Block');
const AgentBlock = require('app/b2c/component/agent/Block');
const ReviewBlock = require('app/b2c/component/review/Block');
const LocationPicker = require('app/ui/input/Location');
const BudgetInput = require('app/ui/input/Budget');
const TagListSelect = require('app/ui/input/TagListSelect');

class View {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {

        this.settings = $.extend(this.defaultOptions(), options);


        this.$container = this.settings.$container;

        this.$projectBlock = $('.project-block',this.$container);
        this.$adBlock = $('.property-block',this.$container);
        this.$agentBlock = $('.agent-block',this.$container);
        this.$reviewBlock = $('.review-block',this.$container);

        this.initialize();
    }

    initialize(){
        new ProjectBlock({
            '$container': this.$projectBlock
        });
        new AdBlock({
            'from': 'home',
            '$container': this.$adBlock
        });
        new AgentBlock({
            'from': 'home',
            '$container': this.$agentBlock
        });
        this.reviewBlock = new ReviewBlock({
            'from': 'home',
            '$container': this.$reviewBlock
        });

        $('.location-picker').each(function(){
            let $container = $(this);
            new LocationPicker({
                '$container': $container,
            })
        });

         $('.budget-wrapper').each(function(){
            let $container = $(this);
            new BudgetInput({
                '$container': $container,
            })
        });

        $('.ad-search-property-type').each(function(){
            let $container = $(this);
            new TagListSelect({
                '$container': $container
            })
        })
    }
}

module.exports = View;
