const UIForm = require('app/ui/Form');

class Form extends UIForm {

    defaultOptions(){
        let options = {
            'formSelector': '.property-form',
        	'$container': null,
        	'draw': function(form,result){},
            'page': null,
            'length': 12,
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        super.initialize();

        this.page = null;
        this.length = this.settings.length;

        this.$sortSelect = $('.sort-select',this.$form);
        this.$propertyTypeSelect = $('.property-type-select',this.$form);

        this.$sortSelect.on('change',this.onSubmit);
        this.$propertyTypeSelect.on('change',this.onSubmit);

        return this;
    }

    setPage(page){
        this.page = page;
    }

    setLength(length){
        this.length = length;
    }

    onDone(response, textStatus, jqXhr){
    	super.onDone(response, textStatus, jqXhr);

    	this.settings.draw(this,response);
    }

    getData(){
        let data = super.getData();
        if(this.page){
            data.push({
                'name': 'page[number]',
                'value': this.page,
            });
        }

        if(this.length){
            data.push({
                'name': 'page[size]',
                'value': this.length,
            });
        }

        return data;
    }


}

module.exports = Form;
