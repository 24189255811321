
const PhotoBlock = require('app/b2c/component/photo/Block');

class View {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);
        this.$container = this.settings.$container;
        this.$photoBlock = $('.property-photo-block',this.$container);
        this.initialize();
    }

    initialize(){
        new PhotoBlock({
            '$container': this.$photoBlock
        });

		this.scrollImage();
        this.similarAdsSwiper();
        this.phoneClick();

    }

	scrollImage(){
		if($(".sticky-image").length > 0){
            const $sectionPhoto = $(".section-photo");
			let pos = ($sectionPhoto.offset().top + $sectionPhoto.outerHeight(true)) - 250;
			if(pos < 0)
				pos = 510;
			$(window).scroll(function () {
				if($(window).scrollTop() >= pos)
                {
                    $(".block-interactive").hide();
                    $(".sticky-image").fadeIn();
                }
				else
                {
                    $(".block-interactive").fadeIn();
					$(".sticky-image").hide();
                }

			});
		}
	}


    similarAdsSwiper(){
        if($(".property-block-similar").length > 0){
            new Swiper('.property-block-similar', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 4.2,
                watchSlidesProgress: false,
                autoplay: {
                    delay: 4000
                },
                breakpoints: {
                    100: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.6,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 4.2,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }

    phoneClick(){
        if($(".agent-phone").length > 0 && window.innerWidth >= 990){
            $(".agent-contact-list").on("click",".agent-phone",function(){
                $(this).toggleClass('opened');
                return false;
            });
        }
    }
}

module.exports = View;
