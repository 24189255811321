class View {

    defaultOptions() {
        return {
        	'$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onRemoveBookmark = this.onRemoveBookmark.bind(this);
        this.onSortChange = this.onSortChange.bind(this);

        this.$container = this.settings.$container;

        this.url = this.$container.data('url');
        this.$subtitle = $('.subtitle',this.$container);
        this.$sortSelect = $('select[name="sort"]',this.$container);
        this.$body = $('.view-body',this.$container);

        this.initialize();
    }

    getIds(){
        return App.getBookmarks('ad');
    }

    initialize(){
        this.$sortSelect.on('change',this.onSortChange);

        document.addEventListener('bsk.bookmark.ad.removed',this.onRemoveBookmark);

        this.draw();
    }

    draw(){
        this.fillSubtitle();
        this.load();
    }

    refresh(){
        this.draw();
    }

    fillSubtitle(){

        let ids = this.getIds();
        let total = ids.length;

        let subtitle = '';

        if(total <= 0){
            subtitle = "Vous n'avez pour le moment aucun bien en favoris";
        }else if(total == 1){
            subtitle = "1 bien en favoris";
        }else{
            subtitle = total + " biens en favoris";
        }

        this.$subtitle.html('').append(subtitle);
    }


    getRequestData(){
        let ids = this.getIds();
        return {
            'from': 'bookmark',
            'search': {
                'id': ids.join(',')
            },
            'sort': this.$sortSelect.val(),
        };
    }

    before(){
        this.$body.html('').append(App.renderLoader());
    }

    after(){
        new lazyload(document.querySelectorAll('.lazy'));
    }

    load(){
        let self = this;
        let ids = this.getIds();
        let total = ids.length;

        if(total <= 0){
            this.$body.html('');
            return;
        }

        this.before();

        let data = this.getRequestData();

        $.ajax({
            'url': this.url,
            'method': "GET",
            'data': data
        })
        .done(function(response,textStatus,jqXhr){
            self.done(response, textStatus, jqXhr);
        })
        .fail(function(jqXhr, textStatus, errorThrown){
            let response = jqXhr.responseText;
            self.fail(response, textStatus, jqXhr);
        })
        .always(function(){
            self.after();
        });

    }

    done(response, textStatus, jqXhr){
        let $list = this.drawList(response);
        this.$body.html('').append($list);
    }

    fail(response, textStatus, jqXhr){
        let $message = $('<div/>')
        .addClass('alert alert-danger')
        .append("Une erreur est advenue lors du chargement des favoris");
        this.$body.html('').append($message);
    }

    drawList(response){
        let $list = $('<ul/>')
        .addClass('property-list')

        let items = response.data;

        for(var i in items){
            let item = items[i];

            let $item = this.drawItem(item);

            $list.append($item);
        }

        return $list;
    }

    drawItem(item){
        let $item = $('<li/>')
        .addClass('property-list-item');

        $item.append(item.html);

        return $item;
    }

    onRemoveBookmark(event){
        this.refresh();
    }

    onSortChange(event){
        this.load();
    }
}

module.exports = View;
