

class Tab {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.$container = this.settings.$container;

        this.$tabList = $('.tab-list',this.$container);
        this.$tabContent = $('.tab-content',this.$container);

        this.initialize();
    }

    initialize(){
        let self = this;

        $('.tab-list-item',this.$tabList).on('click',function(){
            let $link = $(this);
            self.onClickLink($link);
        });

        $('.tab-list-item',this.$tabList).first().trigger('click');
    }

    reset(){

        $('.tab-list-item',this.$tabList).removeClass('active');
        $('.tab',this.$tabContent).hide();
    }

    onClickLink($link){
		if($link.find('a').data('target')){
			let $target = $($link.find('a').data('target'));

			this.reset();

			$link.addClass('active');
			$target.show();
		}
		return false;
    }
}

module.exports = Tab;
