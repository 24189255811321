
const UIForm = require('app/ui/Form');

class Form extends UIForm {

    defaultOptions(){
        let options = {
        	//
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        super.initialize();

        this.$errorContainer = $('.form-error-container',this.$container);
        if(this.$errorContainer.length <= 0){
            this.$errorContainer = $('<div/>')
            .addClass('form-error-container');
            this.$form.before(this.$errorContainer);
        }
        this.$errorContainer.hide();

        this.$resultContainer = $('.form-result-container',this.$container);
        if(this.$resultContainer.length <= 0){
            this.$resultContainer = $('<div/>')
            .addClass('form-result-container');
            this.$form.after(this.$resultContainer);
        }
        this.$resultContainer.hide();

        this.visible = true;
        return this;
    }

    onBeforeSubmit(){
        this.$errorContainer.html('').hide();
        return super.onBeforeSubmit();
    }

    onDone(response, textStatus, jqXhr){
    	super.onDone(response, textStatus, jqXhr);

    	if(response.success){

            let form = this.$form.data('form');
            let event = this.$form.data('event');

            if(!event){
                event = "generate_lead";
            }


            window.setUrlParameter('form',form);

            if(window.App.isAnalyticEnable()){
                let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
                let search = window.addParameterToSearchString(window.location.search,'form',form);
                dataLayer.push({
                    'event': event,
                    'page': {
                        'path': url+"?"+search,
                        'title': 'Contact '+this.$form.data('form'),
                    }
                });
            }


    		this.$resultContainer
    		.html('')
    		.append(response.html)
    		.show();

    		this.$form
    		.hide();
    	}else{
    		this.$errorContainer
    		.html('')
            .append(response.html)
    		.show();
    	}
    }

    onFail(response, textStatus, jqXhr){
    	super.onFail(response, textStatus, jqXhr);

		this.$errorContainer
		.html('')
		.append("Une erreur est advenue lors du traitement, veuillez réessayer plus tard.")
		.show();
    }

    reset(){
    	super.reset();

        this.$errorContainer.html('').hide();
        this.$resultContainer.html('').hide();

    	$('input,select',this.$form)
    	.not(':input[type=hidden]')
        .not(':input[name=valid_from]')//prevent breaking honeypot
        .not(':input[type=checkbox]')
        .not(':input[type=radio]')
    	.val('');

    	$('input[type=checkbox]').prop('checked',false);
    }
}

module.exports = Form;
