
const UIForm = require('app/ui/Form');

const BudgetInput = require('app/ui/input/Budget');
const CheckableInput = require('app/ui/input/Checkable');
const LocationPicker = require('app/ui/input/Location');
const SliderInput = require('app/ui/input/Slider');
const TagListSelect = require('app/ui/input/TagListSelect');

class Form extends UIForm {

    defaultOptions(){
        let options = {
            'formSelector': '.ad-search-form',
        	'$container': null,
            'resetOnHidden': false,
        	'draw': function(form,result){},
            'onClickExtend': function(event){},
            'sort': null,
            'extendedRadius': 30,
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        let self = this;

        super.initialize();

        this.sort = this.settings.sort;
        this.page = null;
        this.extendedSearch = false;
        this.extendedExcept = [];

        this.budgetPicker = new BudgetInput({
            '$container' : $('.budget-wrapper',this.$form)
        });

        $('.checkable',this.$form).each(function(){

            new CheckableInput({
                '$container': $(this)
            });
        });

        $('.slider-container',this.$form).each(function(){

            new SliderInput({
                '$container': $(this)
            });
        });

        this.locationPicker = new LocationPicker({
            '$container': $('.location-picker',this.$form),
        })

        if(!this.isFullForm()){
            this.propertyTypeList =  new TagListSelect({
                '$container': $('.ad-search-property-type',this.$form),
            });
        }

        $("#show-filters").click(function(){
            $('.btn-more-filter').click();
        });

        this.$btnExtend = $('.btn-more-filter',this.$form);
        if(this.$btnExtend.length > 0){
            this.$btnExtend.on('click',this.settings.onClickExtend)
        }
        this.$btnReset = $('.btn-reset',this.$form);
        if(this.$btnReset.length > 0){
            this.$btnReset.on('click',function(){
                self.reset();

            })
        }

        $('.transaction-type-checkbox',this.$form).on('change',function(){
            let $checkboxes = $('.transaction-type-checkbox',self.$form);

            $checkboxes.each(function(){
                let $current = $(this);

                if($current.is(':checked')){
                    $current.parent()
                    .removeClass('btn-white')
                    .addClass('btn-blue')
                }else{
                    $current.parent()
                    .addClass('btn-white')
                    .removeClass('btn-blue')

                }
            })
        })

        let $btnSubmitExtend = $('.btn-more-filter-submit-extend',this.$form);
        if($btnSubmitExtend.length){
            $btnSubmitExtend.on('click',function(){
                $('input[name="extend"]').val(1);
                self.$form.trigger('submit');
            });
        }

        return this;
    }

    isFullForm(){
        return this.$form.hasClass('ad-search-form-full');
    }

    setPage(page){
        this.page = page;
    }

    onChange(event){
        this.setPage(null);
        super.onChange(event);
    }

    mergeWith(form){
        let self = this;
        let inputsToMerge = [
            "location[radius]",
            "location[type]",
            "location[value]",
            "location[keyword]",
            "search[property_type]",
            "search[min_surface]",
            "search[min_price]",
            "search[max_price]",
            "search[transaction_type][]",
            "search[transaction_subtype][]",

            //hidden fields
            'search[max_surface]',
            'search[min_rooms]',
            'search[min_bedrooms]',
            'search[min_bathrooms]',
            'search[with_terrace]',
            'search[with_swimming_pool]',
            'search[is_single_storey]',
            'search[with_elevator]',
            'search[with_cave]',
            'search[with_ac]',
            'search[with_balconie]',
            'search[with_disabled_access]',
            'search[with_parking]',
            'search[is_top_floor]',
            'search[is_facing_south]',
            'search[is_facing_east]',
            'search[is_facing_north]',
            'search[is_facing_west]',
            'search[is_new][]'
        ];

        for(let i in inputsToMerge){
            let inputName = inputsToMerge[i];
            let $input = $('*[name="'+inputName+'"]',form.$form);
            let $myInput = $('*[name="'+inputName+'"]',self.$form);
            switch(inputName){

                case 'search[transaction_type][]':
                    if(this.isFullForm()){
                        $('input[name="search[transaction_type][]"]',self.$form).remove();

                        $('input[name="search[transaction_type][]"]',form.$form).each(function(){
                            let $currentCheckbox = $(this);
                            if($currentCheckbox.is(':checked')){
                                let value = $currentCheckbox.val();
                                self.$form.prepend(
                                    $('<input/>')
                                    .attr('type','hidden')
                                    .attr('name',"search[transaction_type][]")
                                    .val(value)
                                    .attr('checked','checked')
                                    .prop('checked',true)
                                )
                            }
                        })
                    }else{

                        $('input[name="search[transaction_type][]"]',self.$form)
                        .removeAttr('checked')
                        .prop('checked',false)
                        .trigger('change');

                        $('input[name="search[transaction_type][]"]',form.$form).each(function(){
                            let $currentInput = $(this);
                            let value = $currentInput.val();

                            let $checkbox = $('input[name="search[transaction_type][]"][value="'+value+'"]',self.$form);

                            $checkbox
                            .attr('checked','checked')
                            .prop('checked',true)
                            .trigger('change');
                        })
                    }
                break;

                case 'search[is_new][]':

                    $input.each(function(){
                        let $currentInput = $(this);
                        let val = $currentInput.val();
                        $myInput = $('*[name="'+inputName+'"][value="'+val+'"]',self.$form);
                        if($currentInput.is(':checked')){
                            $myInput
                            .attr('checked','checked')
                            .prop('checked',true)
                            .closest('.checkable')
                            .addClass('active');
                        }else{
                            $myInput
                            .removeAttr('checked')
                            .prop('checked',false)
                            .closest('.checkable')
                            .removeClass('active');
                        }
                    });

                case 'search[transaction_subtype][]':

                    $input.each(function(){
                        let $currentInput = $(this);
                        let val = $currentInput.val();
                        $myInput = $('*[name="'+inputName+'"][value="'+val+'"]',self.$form);
                        if($currentInput.is(':checked')){
                            $myInput
                                .attr('checked','checked')
                                .prop('checked',true)
                                .closest('.checkable')
                                .addClass('active');
                        }else{
                            $myInput
                                .removeAttr('checked')
                                .prop('checked',false)
                                .closest('.checkable')
                                .removeClass('active');
                        }
                    });
                break;

                case 'search[with_terrace]':
                case 'search[with_swimming_pool]':
                case 'search[is_single_storey]':
                case 'search[with_elevator]':
                case 'search[with_cave]':
                case 'search[with_ac]':
                case 'search[with_balconie]':
                case 'search[with_disabled_access]':
                case 'search[with_parking]':
                case 'search[is_top_floor]':
                case 'search[is_facing_south]':
                case 'search[is_facing_east]':
                case 'search[is_facing_north]':
                case 'search[is_facing_west]':
                    // checkable
                    if($input.is(':checked')){
                        $myInput
                        .attr('checked','checked')
                        .prop('checked',true)
                        .closest('.checkable')
                        .addClass('active');
                    }else{
                        $myInput
                        .removeAttr('checked')
                        .prop('checked',false)
                        .closest('.checkable')
                        .removeClass('active');
                    }

                break;
                case 'search[property_type]':
                    let fullInputName = 'search[property_type][]';
                    if(this.isFullForm()){
                        let values = [];
                        if(form.propertyTypeList){
                            values = form.propertyTypeList.val();
                        }else{
                            values = $input.val();
                            if(!Array.isArray(values)){
                                values = [values];
                            }
                        }

                        let $myCheckboxes = $('*[name="'+fullInputName+'"]',self.$form);
                        $myCheckboxes.each(function(){
                            let $currentCheckbox = $(this);
                            let currentValue = $currentCheckbox.val();
                            if(values && values.includes(currentValue)){
                                $currentCheckbox
                                .attr('checked','checked')
                                .prop('checked',true)
                                .closest('.checkable')
                                .addClass('active');
                            }else{
                                $currentCheckbox
                                .removeAttr('checked')
                                .prop('checked',false)
                                .closest('.checkable')
                                .removeClass('active');
                            }
                        });
                    }else{
                        let values = [];
                        let $myCheckboxes = $('*[name="'+fullInputName+'"]:checked',form.$form);
                        $myCheckboxes.each(function(){
                            let $currentCheckbox = $(this);
                            let currentValue = $currentCheckbox.val();
                            values.push(currentValue);
                        });
                        self.propertyTypeList.update(values);
                    }
                break;

                case "location[radius]":
                    $myInput.val($input.val())
                    .trigger('change');
                break;

                case "search[min_price]":
                case "search[max_price]":
                    $myInput.val($input.val());
                break;
                default:
                    $myInput.val($input.val());
                break;
            }
        }

        this.locationPicker.setLabelForList(form.locationPicker.getLabelForList());
        this.budgetPicker.update();
    }

    getLabelTransaction(){
        let $selectedTransaction = $('input[name="search[transaction_type][]"]:checked',this.$form);
        let transactionType = '';

        if($selectedTransaction.length == 0){
            $selectedTransaction = $('input[name="search[transaction_type]"]',this.$form);
            transactionType = $selectedTransaction.val();
        }else if($selectedTransaction.length == 1){
            transactionType = $selectedTransaction.val();
        }

        switch(transactionType){
            case 'RENTAL':
                return "à louer";
            break;

            case 'SALE':
                return "à vendre";
            break;

            default:
                return '';
            break;
        }
    }

    getLabelLocalisation(){
        let title = this.locationPicker.getLabelForList();
        if(!title){
            title = "en France";
        }
    	return title;
    }

    onDone(response, textStatus, jqXhr){
    	super.onDone(response, textStatus, jqXhr);

        if(this.extendedSearch && this.extendedCallback){
            this.extendedCallback(this,response);
        }else{
    	   this.settings.draw(this,response);
        }
    }

    reset(){
        let $inputs = $('input,textearea,select',this.$form);

        $inputs.each(function(){
            let $input = $(this);
            if($input.attr('type') == 'checkbox'){
                $input.removeAttr('checked').prop('checked',false);
            }
            else if($input.attr('name') != 'search[transaction_type]' && $input.attr('name') != 'search[transaction_type][]'){
                $input.val('').trigger('change');
            }
            if($input.attr('id') == 'localisationRadius') {
                $input.val('0')
                $('#localisationRadiusLabel').text('0 km')

            }
        });
        this.locationPicker.reset();
    }

    onSortChange(value){
        this.sort = value;
        this.submit();
    }

    getExtendedRadius(){
        let $inputRadius = $('input[name="location[radius]"]',this.$form);
        let additionalRadius= parseInt(this.settings.extendedRadius);
        let initialRadius = $inputRadius.length > 0 ? $inputRadius.val() : additionalRadius;
        initialRadius = parseInt(initialRadius);
        return initialRadius + additionalRadius;
    }

    getData(){
        let data = super.getData();
        if(this.sort){
            data.push({
                'name': 'sort',
                'value': this.sort,
            });
        }

        if(this.extendedSearch){
            let keepParameters=[
                'page[size]',
                'search[transaction_type][]',
                'search[transaction_type]',
                "search[property_type]",
                "search[property_type][]",

                'location[type]',
                'location[value]',
                'sort',
            ];
            data = data.filter(function(item){
                return keepParameters.includes(item.name);
            });

            data.push({
                'name': 'location[radius]',
                'value': this.getExtendedRadius(),
            })

            for(var i in this.extendedExcept){
                let id = this.extendedExcept[i];
                data.push({
                    'name': 'except[]',
                    'value': id
                })
            }
        }


        if(this.page){
            data.push({
                'name': 'page[number]',
                'value': this.page,
            });
        }

        return data;
    }

    submit(){
        this.extendedSearch = false;
        return super.submit();
    }

    submitExtended(callback,except){
        this.extendedSearch = true;
        this.extendedExcept = except;
        this.extendedCallback = function(form,result){
            form.extendedSearch = false;
            form.extendedExcept = [];
            callback(form,result);
        };
        return super.submit();
    }

}

module.exports = Form;


const $formGroupNumber = $(".form-group-number")
if($formGroupNumber.length > 0){
    $formGroupNumber.on("click","span",function(e){
		let $input = $(this).parent(".form-group-number").find('input');
		let $val = $input.val();
		if($val == '')
			$val = 0;
		let $type = parseInt($(this).data('increment'));
		let $newVal = parseInt($val) + $type;

		if($newVal >= 0){
			$input.val($newVal);
		}
		return false;
	});
}
