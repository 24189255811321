class Slider {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onChange = this.onChange.bind(this);

        this.$container = this.settings.$container;

        this.$input = $('input[type="range"]',this.$container);
        this.$label = $(this.$input.data('labelled-by'));
        this.unit = this.$input.data('unit');

        this.initialize();
    }

    initialize(){
        this.$input
        .on('change',this.onChange)
        .on('input',this.onChange);
    }

    onChange(){
        let label = this.$input.val() + " " + this.unit;
        this.$label.html('').append(label);
    }

}

module.exports = Slider;
