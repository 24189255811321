const Slider = require('app/ui/Slider');

class Block extends Slider {

    defaultOptions(){
        let options = {
        };
        return Object.assign(super.defaultOptions(),options);
    }

}

module.exports = Block;
