const Layout = require('app/b2c/agent/page/Layout');
const ReviewBlock = require('app/b2c/component/review/Block');

const List = require('app/b2c/component/agent/ad/List');


class Main extends Layout {

    defaultOptions() {
        let options = {
            'length': 6,
            'lengthOptions': null,
            'openContact': false,
        };
        return Object.assign(super.defaultOptions(), options);
    }

    initialize() {
        super.initialize();

        this.onBeforeSubmitAd = this.onBeforeSubmitAd.bind(this);
        this.drawAdResult = this.drawAdResult.bind(this);


        this.$reviewBlock = $('.review-block', this.$container);
        this.reviewBlock = new ReviewBlock({
            'from': window.location.href,
            '$container': this.$reviewBlock
        });

        this.$propertyFormContainer = $('.property-form-container', this.$container);
        this.$propertyResultContainer = $('.property-result-container', this.$container);

        if (this.settings.openContact) {
            $('.btn-agent-contact', this.$container).trigger('click');
        }

        this.reviewsSwiper();
        this.lastSalesClick();
        return this;
    }

    onBeforeSubmitAd(form) {
        this.$propertyResultContainer
            .html('')
            .append(window.App.renderLoader());
    }

    drawAdResult(form, result) {

        let list = new List({
            'view': this,
            'result': result,
            'length': this.length,
            'lengthOptions': this.settings.lengthOptions,
        });

        this.$propertyResultContainer
            .html('')
            .append(list.render());
    }

    setPage(page) {
        return this.form.setPage(page);
    }

    setLength(length) {
        this.length = length;
        return this.form.setLength(this.length);
    }

    reload() {
        this.form.submit();
    }


    reviewsSwiper() {
        if ($(".review-list-swiper").length > 0 && window.innerWidth <= 1280) {
            new Swiper('.review-list-swiper', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 4.2,
                watchSlidesProgress: false,
                breakpoints: {
                    100: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.3,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 4.2,
                        spaceBetween: 20,
                    },
                }
            });
        }
    }

    lastSalesClick() {
        const $sectionSale = $(".section-sale");
        if ($sectionSale.length > 0 && window.innerWidth <= 990) {
            $sectionSale.on("click", ".see-more", function () {
                $(this).parents('.section-sale').find('.sale-table').addClass('opened');
                $(this).remove();
                return false;
            });
        }
    }

}

module.exports = Main;
