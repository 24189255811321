class Picker {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onAutocomplete = this.onAutocomplete.bind(this);
        this.onLeaveFocus = this.onLeaveFocus.bind(this);

        this.$container = this.settings.$container;
        if (this.$container === null || this.$container.length <= 0) {
            window.notify("missing $container settings for AgentPicker");
        }

        this.$searchInput = $('.agent-input-search', this.$container);
        this.$valueInput = $('.agent-input-value', this.$container);

        this.$propositionContainer = $('.agent-proposition-container', this.$container);

        this.url = this.$container.data('url');
        this.method = this.$container.data('method');

        this.initialize();
    }

    initialize() {
        this.$loader = App.renderLoader();
        this.$propositionList = $('<ul/>')
            .addClass('agent-proposition-list')
            .hide();

        this.$propositionContainer
            .append(this.$loader)
            .append(this.$propositionList);

        window.addEventListener("click", (e) => {
            if (!e.target.className.includes('agent-proposition-list')) {
                this.clearPropositions();
            }
        });

        window.bindAutocomplete(this.$searchInput, this.onAutocomplete, 500);

        this.$container.on('focusout', this.onLeaveFocus);
    }

    onAutocomplete() {
        if (this.$searchInput.val() !== '') {
            this.doSearch();
        } else {
            this.clearPropositions();
        }
    }

    doSearch() {
        let self = this;

        this.onBefore();

        $.ajax({
            'url': this.url,
            'method': this.method,
            'data': {
                'search': {
                    'full_name': this.$searchInput.val()
                },
            }
        })
            .done(function (response, textStatus, jqXhr) {
                self.onDone(response, textStatus, jqXhr);
            })
            .fail(function (jqXhr, textStatus, errorThrown) {
                let response = jqXhr.responseText;
                self.onFail(response, textStatus, jqXhr);
            })
            .always(function () {
                self.onAfter();
            });
    }

    onLeaveFocus(event) {
        //@todo maybe improve this, some wierd behavior can happend
        if (this.$propositionContainer.is(':visible')) {
            if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
                this.$propositionContainer.hide();
                if (this.$typeInput.val() == '') {
                    this.$searchInput.val('');
                }
            }
        }
    }

    onBefore() {
        this.$propositionContainer.show();
        this.$loader.show();
        this.$propositionList.html('');
        this.$valueInput.val('');
    }

    onDone(response, textStatus, jqXhr) {

        let data = response['data'];

        for (let i in data) {
            let item = data[i];

            let $item = this.drawAgentItem(item);

            this.$propositionList.append($item);
        }


    }

    onFail(response, textStatus, jqXhr) {

    }

    onAfter() {
        this.$loader.hide();
        this.$propositionList.show();
    }


    drawItem(name, value) {
        let self = this;

        let $item = $('<li/>')
            .addClass('agent-proposition-list-item')
            .attr('data-value', value)
            .attr('data-label', name)
            .append(name);

        $item.on('click', function () {
            self.onClickPropositionItem($item);
        });

        return $item;
    }

    drawAgentItem(item) {
        let name = item.firstName + ' ' + item.lastName;
        let value = item.id;
        return this.drawItem(name, value);
    }

    onClickPropositionItem($item) {
        let label = $item.data('label');
        let value = $item.data('value');

        if (typeof value == 'object') {
            value = JSON.stringify(value);
        }

        this.$valueInput.val(value);
        this.$searchInput.val(label);

        this.clearPropositions();
    }

    clearPropositions() {
        this.$propositionContainer.hide();
        this.$propositionList.html('');
    }

    reset() {
        this.$valueInput.val('');
        this.$searchInput.val('');
    }

}

module.exports = Picker;
