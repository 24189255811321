const Layout = require('app/b2c/agent/page/Layout');

class Review extends Layout {

    defaultOptions(){
        let options = {
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        super.initialize();
        return this;
    }

}

module.exports = Review;
