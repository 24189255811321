const Layout = require('app/b2c/agent/page/Layout');
const Pagination = require('app/ui/Pagination');
const Form = require('app/ui/Form');

class Team extends Layout {

    defaultOptions(){
        let options = {
            '#formContainer': null,
            'result': null,//give api result to init pagination at startup
        };
        return Object.assign(super.defaultOptions(),options);
    }

    initialize() {
        this.onClickPaginationLink = this.onClickPaginationLink.bind(this);
        this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
        this.onDone = this.onDone.bind(this);

        super.initialize();

        this.$formContainer = this.settings.$formContainer;
        this.result = this.settings.result;
        this.page = 1;
        this.$listContainer = $('.agent-list-container',this.$container);


        if(this.$formContainer){
            this.form = new Form({
                'before': this.onBeforeSubmit,
                '$container': this.$formContainer,
                'done': this.onDone,
            });
        }
        if(this.result){
            this.drawPagination();
        }

        return this;
    }

    drawPagination(){
        if(this.result.meta.last_page == 1){
            return;
        }
        let pagination = new Pagination({
            'pageLinks': this.result.meta.links,
            'onClickLink': this.onClickPaginationLink,
        });

        this.$listContainer.append(pagination.render());
    }

    onClickPaginationLink(link,$link,pagination){
        this.page = link.page;
        this.reload();
    }

    reload(){
        $('input[name="page[number]"]',this.$formContainer).val(this.page);
        this.form.submit();
    }

    onDone(form,result){
        this.result = result;

        this.$listContainer.html('');
        let $list = $('<ul/>')
        .addClass('agent-list');

        if(this.result.data.length > 0){
            for(let i in this.result.data){
                let item = this.result.data[i];

                $list.append(
                    $('<li/>')
                    .addClass('agent-list-item')
                    .append(item.html)
                )
            }
            this.$listContainer.append($list);
            this.drawPagination();
        }else{
            let $emptyMessage = $('<div/>')
            .addClass('alert alert-warning')
            .append("Aucun conseiller trouvé.");
            this.$listContainer.append($emptyMessage);

        }

    }

    onBeforeSubmit(form){
        this.$listContainer
        .html('')
        .append(window.App.renderLoader());
    }


}

module.exports = Team;
