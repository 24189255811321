const ProjectBlock = require('app/b2b/component/lp/Block');

class View {

    defaultOptions() {
        return {
            '$container': null,
        };
    }

    constructor(options) {

        this.settings = $.extend(this.defaultOptions(), options);


        this.$container = this.settings.$container;

        this.$projectBlock = $('.project-block-lp',this.$container);

        this.initialize();
    }

    initialize(){
        new ProjectBlock({
            '$container': this.$projectBlock
        });

        $('.location-picker').each(function(){
            let $container = $(this);
            new LocationPicker({
                '$container': $container,
            })
        });

        $('.budget-wrapper').each(function(){
            let $container = $(this);
            new BudgetInput({
                '$container': $container,
            })
        });

        $('.ad-search-property-type').each(function(){
            let $container = $(this);
            new TagListSelect({
                '$container': $container
            })
        })
    }
}

module.exports = View;
