class View {

    constructor(options) {
        this.initialize();
    }

    initialize() {
        this.statutsClick();
        this.faqClick();
        this.stepsSwiper();
        this.videosSwiper();
    }

    statutsClick() {
        const $statutsDetail = $(".statuts-detail");
        $statutsDetail.on("click", ".statuts-link", function () {
            $(this).parent().toggleClass('opened');
            return false;
        });
        $statutsDetail.on("click", ".statuts-close", function () {
            $(this).parent().removeClass('opened');
            return false;
        });
    }

    faqClick() {
        $("body").on("click", ".faq-element", function () {
            $(this).toggleClass('opened');
        });
    }

    stepsSwiper() {
        if ($(".steps-swiper").length > 0 && window.visualViewport.width <= 990) {
            new Swiper('.steps-swiper', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.1,
                freeMode: true,
                watchSlidesProgress: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.steps-swiper-button-next',
                    prevEl: '.steps-swiper-button-prev',
                },
                breakpoints: {
                    1: {
                        slidesPerView: 1.3,
                        spaceBetween: 20,
                    },
                    400: {
                        slidesPerView: 1.6,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.9,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.5,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 2.8,
                        spaceBetween: 40,
                    },
                }
            });
        }
    }

    videosSwiper() {
        if ($(".videos-swiper").length > 0 && window.visualViewport.width <= 990) {
            new Swiper('.videos-swiper', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.1,
                freeMode: true,
                watchSlidesProgress: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.videos-swiper-button-next',
                    prevEl: '.videos-swiper-button-prev',
                },
                breakpoints: {
                    1: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    400: {
                        slidesPerView: 1.4,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.6,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.5,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 2.8,
                        spaceBetween: 40,
                    },
                }
            });
        }
    }
}

module.exports = View;
