class View {

    constructor(options) {
        this.initialize();
    }

    initialize(){
		this.assetsSwiper();
    }

    assetsSwiper(){
        if($(".assets-swiper").length > 0 && window.innerWidth <= 990){
            new Swiper('.assets-swiper' , {
                speed: 400,
                spaceBetween: 0,
                slidesPerView: 1.1,
                freeMode: true,
                watchSlidesProgress: false,
                breakpoints: {
                    0: {
                        slidesPerView: 1.1,
                        spaceBetween: 20,
                    },
                    400: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 1.2,
                        spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 1.2,
                        spaceBetween: 40,
                    },
                }
            });
        }
    }
}

module.exports = View;
