const DepartmentBlock = require('app/b2c/component/department/Block');
const AgentBlock = require('app/b2c/component/agent/Block');
const Form = require('app/b2c/review/search/Form');
const List = require('app/b2c/review/search/List');

class View {

    defaultOptions() {
        return {
        	'$container': null,
            '$formContainer': null,

            'result': null,//give api result to init pagination at startup
        };
    }

    constructor(options) {
        this.settings = $.extend(this.defaultOptions(), options);

        this.onBeforeSubmit = this.onBeforeSubmit.bind(this);
        this.drawResult = this.drawResult.bind(this);

        this.$container = this.settings.$container;

        this.$departmentBlock = $('.department-block',this.$container);

        this.$formContainer = this.settings.$formContainer;
        this.$departmentSelect = $('.department-select',this.$formContainer);
        this.$resultContainer = $('.review-search-result-container',this.$container);

        this.$agentBlock = $('.agent-block',this.$container);


        this.initialize();
    }

    initialize(){
        let self = this;

        this.$departmentBlock = new DepartmentBlock({
            '$container': this.$departmentBlock
        });


        $('.department-slide',this.$container).on('click',function(event){
            let $slide = $(this);
            self.onClickDepartmentSlide($slide);
        });

		$(".alternate-previous",this.$container).on('click',function(event){
            $(this).parents('.swiper-container').find('.btn-previous').click();
			if($(this).parents('.swiper-container').find('.btn-previous').hasClass('swiper-button-disabled'))
				$(this).addClass('disabled');
			$(this).parents('.swiper-container').find('.alternate-next').removeClass('disabled');
			return false;
        });
		$(".alternate-next",this.$container).on('click',function(event){
            $(this).parents('.swiper-container').find('.btn-next').click();
			if($(this).parents('.swiper-container').find('.btn-next').hasClass('swiper-button-disabled'))
				$(this).addClass('disabled');
			$(this).parents('.swiper-container').find('.alternate-previous').removeClass('disabled');
			return false;
        });

		$(".department-select-arrow",this.$container).on("click",function(event){
			//self.$departmentSelect.trigger('focus');

			let span = document.getElementById("department-select-arrow");
            let select = document.getElementById("search_by_department");

			span.addEventListener("mousedown",function(){
                let evt = event
				setTimeout(function(){
					select.dispatchEvent(evt)
				})
			})
		});

        new AgentBlock({
            'from': 'home',
            '$container': this.$agentBlock
        });

        this.form = new Form({
            'before': this.onBeforeSubmit,
            '$container': this.$formContainer,
            'draw': this.drawResult,
        });

        if(this.settings.result){
            this.drawResult(this.form,this.settings.result);
        }

		this.departmentSlider();
    }

    onClickDepartmentSlide($slide){
        let slug = $slide.data('slug');

        let $options = $('option',this.$departmentSelect);
        $options.prop('selected',false);

        let $option = $('option[data-slug="'+slug+'"]',this.$departmentSelect);
        $option.prop('selected',true);

        this.$departmentSelect.trigger('change');
    }

    drawResult(form,result){

        let list = new List({
            'form': form,
            'result': result,
        });

        this.$resultContainer
        .html('')
        .append(list.render());
    }

    onBeforeSubmit(form){
        this.$resultContainer
        .html('')
        .append(window.App.renderLoader());
    }

	departmentSlider(){
        if($(".department-block").length > 0){
            new Swiper('.department-block', {
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 4.2,
                watchSlidesProgress: false,
                autoplay: {
                    delay: 4000,
                },
                breakpoints: {
                    100: {
                        slidesPerView: 1.4,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                    },
                    991: {
                        slidesPerView: 2.9,
                        spaceBetween: 20,
                    },
                    1280: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                    },
                }
            });
        }
	}
}

module.exports = View;
